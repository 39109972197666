/* tslint:disable */
/* eslint-disable */
/**
 * kusabi-gateway API
 * # Description This is kusabi-gateway API. 
 *
 * The version of the OpenAPI document: 1.0.7
 * Contact: tomohiko.ozawa@sony.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 担当者情報
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * ID
     * @type {string}
     * @memberof Agent
     */
    'id'?: string;
    /**
     * 氏名
     * @type {string}
     * @memberof Agent
     */
    'name': string;
    /**
     * 事業所ID
     * @type {number}
     * @memberof Agent
     */
    'officeId': number;
    /**
     * 会社名
     * @type {string}
     * @memberof Agent
     */
    'companyName'?: string;
    /**
     * 事業所名
     * @type {string}
     * @memberof Agent
     */
    'officeName'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Agent
     */
    'mail': string;
    /**
     * 有効・無効
     * @type {boolean}
     * @memberof Agent
     */
    'enabled': boolean;
    /**
     * cognitoでメール認証済みかどうか
     * @type {boolean}
     * @memberof Agent
     */
    'mailVerified': boolean;
    /**
     * 
     * @type {PropertyAuthority}
     * @memberof Agent
     */
    'propertyAuthority': PropertyAuthority;
    /**
     * 
     * @type {MemberAuthority}
     * @memberof Agent
     */
    'memberAuthority': MemberAuthority;
    /**
     * 
     * @type {InquiryAuthority}
     * @memberof Agent
     */
    'inquiryAuthority': InquiryAuthority;
    /**
     * 
     * @type {MailDeliverySummaryAuthority}
     * @memberof Agent
     */
    'mailDeliverySummaryAuthority': MailDeliverySummaryAuthority;
    /**
     * 
     * @type {MailTemplateAuthority}
     * @memberof Agent
     */
    'mailTemplateAuthority': MailTemplateAuthority;
    /**
     * 
     * @type {SettingAdminAuthority}
     * @memberof Agent
     */
    'settingAdminAuthority': SettingAdminAuthority;
    /**
     * 
     * @type {CsvDownloadAuthority}
     * @memberof Agent
     */
    'csvDownloadAuthority': CsvDownloadAuthority;
    /**
     * 作成日時
     * @type {string}
     * @memberof Agent
     */
    'createdAt'?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Agent
     */
    'updatedAt'?: string;
    /**
     * パスワード変更が必要か否か
     * @type {boolean}
     * @memberof Agent
     */
    'shouldChangePassword'?: boolean;
}
/**
 * Agents
 * @export
 * @interface Agents
 */
export interface Agents {
    /**
     * 総件数
     * @type {number}
     * @memberof Agents
     */
    'size'?: number;
    /**
     * 担当者情報リスト
     * @type {Array<Agent>}
     * @memberof Agents
     */
    'agents': Array<Agent>;
}
/**
 * 集計期間
 * @export
 * @interface AggregateConditions
 */
export interface AggregateConditions {
    /**
     * 送信日（開始）
     * @type {string}
     * @memberof AggregateConditions
     */
    'startDate': string;
    /**
     * 送信日（終了）
     * @type {string}
     * @memberof AggregateConditions
     */
    'endDate': string;
}
/**
 * エリア内訳
 * @export
 * @interface AreaMapping
 */
export interface AreaMapping {
    /**
     * ID
     * @type {number}
     * @memberof AreaMapping
     */
    'id'?: number;
    /**
     * 区・市名
     * @type {string}
     * @memberof AreaMapping
     */
    'cityName'?: string;
    /**
     * 県名
     * @type {string}
     * @memberof AreaMapping
     */
    'prefectureName'?: string;
    /**
     * エリア名
     * @type {string}
     * @memberof AreaMapping
     */
    'areaName'?: string;
}
/**
 * エリア内訳群
 * @export
 * @interface AreaMappings
 */
export interface AreaMappings {
    /**
     * エリア内訳群
     * @type {Array<AreaMapping>}
     * @memberof AreaMappings
     */
    'areaMappings': Array<AreaMapping>;
}
/**
 * AI査定結果
 * @export
 * @interface Assessment
 */
export interface Assessment {
    /**
     * AI査定結果ID
     * @type {number}
     * @memberof Assessment
     */
    'id'?: number;
    /**
     * 
     * @type {AssessmentAddress}
     * @memberof Assessment
     */
    'addressInfo': AssessmentAddress;
    /**
     * 
     * @type {AssessmentMansion}
     * @memberof Assessment
     */
    'mansionInfo': AssessmentMansion;
    /**
     * 
     * @type {AssessmentRoom}
     * @memberof Assessment
     */
    'roomInfo': AssessmentRoom;
    /**
     * 
     * @type {TenancyStatus}
     * @memberof Assessment
     */
    'tenancyStatus': TenancyStatus;
    /**
     * 
     * @type {RentManagementStatus}
     * @memberof Assessment
     */
    'rentManagementStatus'?: RentManagementStatus;
    /**
     * 
     * @type {RentType}
     * @memberof Assessment
     */
    'rentType'?: RentType;
    /**
     * 賃料
     * @type {number}
     * @memberof Assessment
     */
    'rentPrice'?: number;
    /**
     * 
     * @type {RentManagementFeeType}
     * @memberof Assessment
     */
    'rentManagementFeeType'?: RentManagementFeeType;
    /**
     * 賃貸管理手数料
     * @type {number}
     * @memberof Assessment
     */
    'rentManagementFee'?: number;
    /**
     * 賃貸管理手数料（比率）
     * @type {number}
     * @memberof Assessment
     */
    'rentManagementFeeRate'?: number;
    /**
     * 賃貸管理会社
     * @type {string}
     * @memberof Assessment
     */
    'rentManagementCompany'?: string;
    /**
     * 管理費・修繕積立金
     * @type {number}
     * @memberof Assessment
     */
    'maintenanceFee'?: number;
    /**
     * 名前（姓）
     * @type {string}
     * @memberof Assessment
     */
    'lastName': string;
    /**
     * 名前（名）
     * @type {string}
     * @memberof Assessment
     */
    'firstName': string;
    /**
     * ふりがな（姓）
     * @type {string}
     * @memberof Assessment
     */
    'lastNameKana': string;
    /**
     * ふりがな（名）
     * @type {string}
     * @memberof Assessment
     */
    'firstNameKana': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Assessment
     */
    'mailAddress': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Assessment
     */
    'phoneNumber'?: string;
    /**
     * パートナーID
     * @type {string}
     * @memberof Assessment
     */
    'partnerId'?: string;
    /**
     * 売却査定価格（下限）
     * @type {number}
     * @memberof Assessment
     */
    'priceLower'?: number;
    /**
     * 売却査定価格（上限）
     * @type {number}
     * @memberof Assessment
     */
    'priceUpper'?: number;
    /**
     * 買取査定価格
     * @type {number}
     * @memberof Assessment
     */
    'purchasePrice'?: number;
    /**
     * 問合せID
     * @type {number}
     * @memberof Assessment
     */
    'inquiryId'?: number;
}
/**
 * 住所情報
 * @export
 * @interface AssessmentAddress
 */
export interface AssessmentAddress {
    /**
     * 都道府県
     * @type {string}
     * @memberof AssessmentAddress
     */
    'prefectureName': string;
    /**
     * 市区町村
     * @type {string}
     * @memberof AssessmentAddress
     */
    'cityName': string;
    /**
     * 町丁目
     * @type {string}
     * @memberof AssessmentAddress
     */
    'townName': string;
    /**
     * 路線名
     * @type {string}
     * @memberof AssessmentAddress
     */
    'lineName'?: string;
    /**
     * 駅名
     * @type {string}
     * @memberof AssessmentAddress
     */
    'stationName'?: string;
    /**
     * 駅徒歩分
     * @type {number}
     * @memberof AssessmentAddress
     */
    'walkMinute'?: number;
}
/**
 * AssessmentArea
 * @export
 * @interface AssessmentCriteriaArea
 */
export interface AssessmentCriteriaArea {
    /**
     * 
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'id'?: number;
    /**
     * 名前
     * @type {string}
     * @memberof AssessmentCriteriaArea
     */
    'name': string;
    /**
     * 都道府県
     * @type {string}
     * @memberof AssessmentCriteriaArea
     */
    'prefectureName': string;
    /**
     * グループ
     * @type {string}
     * @memberof AssessmentCriteriaArea
     */
    'groupCode': string;
    /**
     * 築１年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within1Year': number;
    /**
     * 築５年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within5Years'?: number;
    /**
     * 築１０年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within10Years'?: number;
    /**
     * 築１５年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within15Years'?: number;
    /**
     * 築２０年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within20Years'?: number;
    /**
     * 築２５年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within25Years'?: number;
    /**
     * 築３０年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within30Years'?: number;
    /**
     * 築３５年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within35Years'?: number;
    /**
     * 築４０年以内
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'within40Years'?: number;
    /**
     * 築４１年以上
     * @type {number}
     * @memberof AssessmentCriteriaArea
     */
    'over40Years': number;
    /**
     * 
     * @type {Array<City>}
     * @memberof AssessmentCriteriaArea
     */
    'cities'?: Array<City>;
}
/**
 * エリア別評価基準のリスト
 * @export
 * @interface AssessmentCriteriaAreas
 */
export interface AssessmentCriteriaAreas {
    /**
     * エリア別評価基準情報
     * @type {Array<AssessmentCriteriaArea>}
     * @memberof AssessmentCriteriaAreas
     */
    'assessmentAreas': Array<AssessmentCriteriaArea>;
}
/**
 * 特別駅評価基準
 * @export
 * @interface AssessmentCriteriaStation
 */
export interface AssessmentCriteriaStation {
    /**
     * 
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'id'?: number;
    /**
     * 名前
     * @type {string}
     * @memberof AssessmentCriteriaStation
     */
    'name': string;
    /**
     * 築１年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within1Year': number;
    /**
     * 築５年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within5Years'?: number;
    /**
     * 築１０年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within10Years'?: number;
    /**
     * 築１５年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within15Years'?: number;
    /**
     * 築２０年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within20Years'?: number;
    /**
     * 築２５年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within25Years'?: number;
    /**
     * 築３０年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within30Years'?: number;
    /**
     * 築３５年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within35Years'?: number;
    /**
     * 築４０年以内
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'within40Years'?: number;
    /**
     * 築４１年以上
     * @type {number}
     * @memberof AssessmentCriteriaStation
     */
    'over40Years': number;
    /**
     * 対象駅
     * @type {Array<Station>}
     * @memberof AssessmentCriteriaStation
     */
    'stations': Array<Station>;
}
/**
 * 特別駅評価基準のリスト
 * @export
 * @interface AssessmentCriteriaStations
 */
export interface AssessmentCriteriaStations {
    /**
     * 評価基準情報
     * @type {Array<AssessmentCriteriaStation>}
     * @memberof AssessmentCriteriaStations
     */
    'specialStations': Array<AssessmentCriteriaStation>;
}
/**
 * マンション情報
 * @export
 * @interface AssessmentMansion
 */
export interface AssessmentMansion {
    /**
     * マンション名
     * @type {string}
     * @memberof AssessmentMansion
     */
    'mansionName'?: string;
    /**
     * 建物階数
     * @type {number}
     * @memberof AssessmentMansion
     */
    'totalStory'?: number;
    /**
     * 築年月
     * @type {string}
     * @memberof AssessmentMansion
     */
    'builtDate': string;
    /**
     * 
     * @type {Structure}
     * @memberof AssessmentMansion
     */
    'structure': Structure;
    /**
     * 総戸数
     * @type {number}
     * @memberof AssessmentMansion
     */
    'totalUnit'?: number;
    /**
     * 土地権利
     * @type {string}
     * @memberof AssessmentMansion
     */
    'landRight'?: string;
    /**
     * マンションID
     * @type {string}
     * @memberof AssessmentMansion
     */
    'mansionId'?: string;
}
/**
 * 部屋情報
 * @export
 * @interface AssessmentRoom
 */
export interface AssessmentRoom {
    /**
     * 住戸ID
     * @type {number}
     * @memberof AssessmentRoom
     */
    'unitId'?: number;
    /**
     * 部屋番号
     * @type {string}
     * @memberof AssessmentRoom
     */
    'roomNumber'?: string;
    /**
     * 間取り
     * @type {string}
     * @memberof AssessmentRoom
     */
    'floorPlan'?: string;
    /**
     * 所在階
     * @type {number}
     * @memberof AssessmentRoom
     */
    'story'?: number;
    /**
     * 専有面積
     * @type {number}
     * @memberof AssessmentRoom
     */
    'unitSize'?: number;
    /**
     * バルコニー面積
     * @type {number}
     * @memberof AssessmentRoom
     */
    'balconySize'?: number;
    /**
     * 
     * @type {BathroomType}
     * @memberof AssessmentRoom
     */
    'bathroomType'?: BathroomType;
}
/**
 * 方向
 * @export
 * @enum {string}
 */

export const BalconyDirection = {
    NORTH: '北',
    NORTH_EAST: '北東',
    EAST: '東',
    SOUTH_EAST: '南東',
    SOUTH: '南',
    SOUTH_WEST: '南西',
    WEST: '西',
    NORTH_WEST: '北西'
} as const;

export type BalconyDirection = typeof BalconyDirection[keyof typeof BalconyDirection];


/**
 * 浴室設備
 * @export
 * @enum {string}
 */

export const BathroomType = {
    SEPARATE_BATH_AND_TOILET: 'バス・トイレ別',
    UNIT_BATH: '3点ユニットバス'
} as const;

export type BathroomType = typeof BathroomType[keyof typeof BathroomType];


/**
 * ブラックリストのデータ
 * @export
 * @interface BlacklistColumn
 */
export interface BlacklistColumn {
    /**
     * ID
     * @type {number}
     * @memberof BlacklistColumn
     */
    'id'?: number;
    /**
     * 会員ID
     * @type {number}
     * @memberof BlacklistColumn
     */
    'memberId': number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof BlacklistColumn
     */
    'mailAddress': string;
    /**
     * エラーステータス
     * @type {number}
     * @memberof BlacklistColumn
     */
    'errorStatus': BlacklistColumnErrorStatusEnum;
}

export const BlacklistColumnErrorStatusEnum = {
    BOUNCE: 50,
    SPAM: 60
} as const;

export type BlacklistColumnErrorStatusEnum = typeof BlacklistColumnErrorStatusEnum[keyof typeof BlacklistColumnErrorStatusEnum];

/**
 * ブラックリストの登録データ一覧
 * @export
 * @interface BlacklistColumns
 */
export interface BlacklistColumns {
    /**
     * データ一覧
     * @type {Array<BlacklistColumn>}
     * @memberof BlacklistColumns
     */
    'columns'?: Array<BlacklistColumn>;
}
/**
 * 市区町村のリスト
 * @export
 * @interface Cities
 */
export interface Cities {
    /**
     * 
     * @type {Array<City>}
     * @memberof Cities
     */
    'cities'?: Array<City>;
}
/**
 * 市区町村
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {number}
     * @memberof City
     */
    'id'?: number;
    /**
     * 市区町村名
     * @type {string}
     * @memberof City
     */
    'cityName': string;
    /**
     * 市区町村名カナ
     * @type {string}
     * @memberof City
     */
    'cityNameKana'?: string;
}
/**
 * ContractsProperties
 * @export
 * @interface ContractsProperties
 */
export interface ContractsProperties {
    /**
     * 成約済物件一覧
     * @type {Array<Property>}
     * @memberof ContractsProperties
     */
    'contractsProperties': Array<Property>;
    /**
     * 成約物件件数
     * @type {number}
     * @memberof ContractsProperties
     */
    'contractsPropertiesCount'?: number;
}
/**
 * CSVダウンロード権限
 * @export
 * @enum {string}
 */

export const CsvDownloadAuthority = {
    NO_AUTHORITY: '権限なし',
    DOWNLOAD: 'ダウンロード可'
} as const;

export type CsvDownloadAuthority = typeof CsvDownloadAuthority[keyof typeof CsvDownloadAuthority];


/**
 * doorman住所情報
 * @export
 * @interface DoormanAddress
 */
export interface DoormanAddress {
    /**
     * 都道府県名
     * @type {string}
     * @memberof DoormanAddress
     */
    'pref'?: string;
    /**
     * 市区町村名
     * @type {string}
     * @memberof DoormanAddress
     */
    'city'?: string;
    /**
     * 丁目
     * @type {string}
     * @memberof DoormanAddress
     */
    'town'?: string;
    /**
     * 番地
     * @type {string}
     * @memberof DoormanAddress
     */
    'street'?: string;
}
/**
 * Doormann市区町村リスト
 * @export
 * @interface DoormanCities
 */
export interface DoormanCities {
    /**
     * 市区町村リスト
     * @type {Array<DoormanCity>}
     * @memberof DoormanCities
     */
    'cities'?: Array<DoormanCity>;
}
/**
 * Doorman市区町村情報
 * @export
 * @interface DoormanCity
 */
export interface DoormanCity {
    /**
     * 市区町村コード
     * @type {number}
     * @memberof DoormanCity
     */
    'id'?: number;
    /**
     * 市区町村名
     * @type {string}
     * @memberof DoormanCity
     */
    'name'?: string;
    /**
     * 市区町村カナ名
     * @type {string}
     * @memberof DoormanCity
     */
    'kana'?: string;
}
/**
 * 現況
 * @export
 * @enum {string}
 */

export const DoormanCurrentStatus = {
    LIVE: '居住中',
    VACANCY: '空家',
    LEASE: '賃貸中',
    UNFINISHED: '未完成',
    EMPTY_LOT: '更地',
    GOOD_ON: '上物有'
} as const;

export type DoormanCurrentStatus = typeof DoormanCurrentStatus[keyof typeof DoormanCurrentStatus];


/**
 * Doorman路線情報
 * @export
 * @interface DoormanLine
 */
export interface DoormanLine {
    /**
     * 路線ID
     * @type {number}
     * @memberof DoormanLine
     */
    'id'?: number;
    /**
     * 路線名
     * @type {string}
     * @memberof DoormanLine
     */
    'name'?: string;
    /**
     * 鉄道会社ID
     * @type {number}
     * @memberof DoormanLine
     */
    'companyId'?: number;
    /**
     * 鉄道会社名
     * @type {string}
     * @memberof DoormanLine
     */
    'companyName'?: string;
}
/**
 * Doormann路線リスト
 * @export
 * @interface DoormanLines
 */
export interface DoormanLines {
    /**
     * 路線リスト
     * @type {Array<DoormanLine>}
     * @memberof DoormanLines
     */
    'lines'?: Array<DoormanLine>;
}
/**
 * Doormanマンション情報
 * @export
 * @interface DoormanMansion
 */
export interface DoormanMansion {
    /**
     * 棟ID
     * @type {number}
     * @memberof DoormanMansion
     */
    'id'?: number;
    /**
     * マンション名
     * @type {string}
     * @memberof DoormanMansion
     */
    'name'?: string;
    /**
     * 
     * @type {DoormanAddress}
     * @memberof DoormanMansion
     */
    'address'?: DoormanAddress;
    /**
     * 築年月
     * @type {string}
     * @memberof DoormanMansion
     */
    'builtDate'?: string;
}
/**
 * Doormannマンションリスト
 * @export
 * @interface DoormanMansions
 */
export interface DoormanMansions {
    /**
     * マンションリスト
     * @type {Array<DoormanMansion>}
     * @memberof DoormanMansions
     */
    'mansions'?: Array<DoormanMansion>;
}
/**
 * マンション検索ボディ
 * @export
 * @interface DoormanMansionsRequest
 */
export interface DoormanMansionsRequest {
    /**
     * マンション名
     * @type {string}
     * @memberof DoormanMansionsRequest
     */
    'name': string;
    /**
     * 都道府県コード
     * @type {number}
     * @memberof DoormanMansionsRequest
     */
    'prefId': number;
    /**
     * 市区町村ID
     * @type {number}
     * @memberof DoormanMansionsRequest
     */
    'cityId'?: number;
    /**
     * 町丁目名
     * @type {string}
     * @memberof DoormanMansionsRequest
     */
    'town'?: string;
}
/**
 * Doorman物件データ
 * @export
 * @interface DoormanProperty
 */
export interface DoormanProperty {
    /**
     * 物件ID
     * @type {number}
     * @memberof DoormanProperty
     */
    'id'?: number;
    /**
     * 
     * @type {PropertyType}
     * @memberof DoormanProperty
     */
    'type'?: PropertyType;
}
/**
 * Doorman駅情報
 * @export
 * @interface DoormanStation
 */
export interface DoormanStation {
    /**
     * 沿線コード
     * @type {number}
     * @memberof DoormanStation
     */
    'id'?: number;
    /**
     * 駅名
     * @type {string}
     * @memberof DoormanStation
     */
    'name'?: string;
    /**
     * 駅コード
     * @type {number}
     * @memberof DoormanStation
     */
    'lineId'?: number;
    /**
     * 緯度
     * @type {number}
     * @memberof DoormanStation
     */
    'lat'?: number;
    /**
     * 経度
     * @type {number}
     * @memberof DoormanStation
     */
    'lng'?: number;
}
/**
 * Doormann駅リスト
 * @export
 * @interface DoormanStations
 */
export interface DoormanStations {
    /**
     * 駅リスト
     * @type {Array<DoormanStation>}
     * @memberof DoormanStations
     */
    'stations'?: Array<DoormanStation>;
}
/**
 * Doorman町丁目
 * @export
 * @interface DoormanTown
 */
export interface DoormanTown {
    /**
     * 町丁目ID
     * @type {number}
     * @memberof DoormanTown
     */
    'id'?: number;
    /**
     * 町丁目名
     * @type {string}
     * @memberof DoormanTown
     */
    'name'?: string;
    /**
     * 市区町村カナ名
     * @type {string}
     * @memberof DoormanTown
     */
    'kana'?: string;
    /**
     * 緯度
     * @type {number}
     * @memberof DoormanTown
     */
    'lat'?: number;
    /**
     * 経度
     * @type {number}
     * @memberof DoormanTown
     */
    'lng'?: number;
}
/**
 * Doormann町丁目リスト
 * @export
 * @interface DoormanTowns
 */
export interface DoormanTowns {
    /**
     * 町丁目リスト
     * @type {Array<DoormanTown>}
     * @memberof DoormanTowns
     */
    'towns'?: Array<DoormanTown>;
}
/**
 * Doorman交通情報
 * @export
 * @interface DoormanTraffic
 */
export interface DoormanTraffic {
    /**
     * 路線名
     * @type {string}
     * @memberof DoormanTraffic
     */
    'line'?: string;
    /**
     * 駅名
     * @type {string}
     * @memberof DoormanTraffic
     */
    'station'?: string;
    /**
     * 駅徒歩分
     * @type {number}
     * @memberof DoormanTraffic
     */
    'walk'?: number;
}
/**
 * Doorman住戸
 * @export
 * @interface DoormanUnit
 */
export interface DoormanUnit {
    /**
     * 住戸ID
     * @type {number}
     * @memberof DoormanUnit
     */
    'id'?: number;
    /**
     * 部屋番号
     * @type {string}
     * @memberof DoormanUnit
     */
    'number'?: string;
}
/**
 * Doorman住戸リスト
 * @export
 * @interface DoormanUnits
 */
export interface DoormanUnits {
    /**
     * 住戸リスト
     * @type {Array<DoormanUnit>}
     * @memberof DoormanUnits
     */
    'units'?: Array<DoormanUnit>;
}
/**
 * 問合せ検索リクエスト
 * @export
 * @interface DownloadInquiriesRequest
 */
export interface DownloadInquiriesRequest {
    /**
     * 問合せ日（開始）
     * @type {string}
     * @memberof DownloadInquiriesRequest
     */
    'from'?: string;
    /**
     * 問合せ日（終了）
     * @type {string}
     * @memberof DownloadInquiriesRequest
     */
    'to'?: string;
    /**
     * 氏名
     * @type {string}
     * @memberof DownloadInquiriesRequest
     */
    'name'?: string;
    /**
     * 
     * @type {InquiryType}
     * @memberof DownloadInquiriesRequest
     */
    'type'?: InquiryType;
    /**
     * 問合せID
     * @type {number}
     * @memberof DownloadInquiriesRequest
     */
    'inquiryId'?: number;
    /**
     * 
     * @type {InquiryStatus}
     * @memberof DownloadInquiriesRequest
     */
    'status'?: InquiryStatus;
    /**
     * パートナーID
     * @type {string}
     * @memberof DownloadInquiriesRequest
     */
    'partnerId'?: string;
    /**
     * ステータス更新
     * @type {boolean}
     * @memberof DownloadInquiriesRequest
     */
    'updatesStatus'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DownloadInquiriesRequest
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof DownloadInquiriesRequest
     */
    'offset'?: number;
}
/**
 * Error
 * @export
 * @interface ErrorItem
 */
export interface ErrorItem {
    /**
     * Message
     * @type {string}
     * @memberof ErrorItem
     */
    'message': string;
}
/**
 * Errors
 * @export
 * @interface Errors
 */
export interface Errors {
    /**
     * 
     * @type {Array<ErrorItem>}
     * @memberof Errors
     */
    'errors': Array<ErrorItem>;
}
/**
 * 配信メール情報
 * @export
 * @interface FollowupMail
 */
export interface FollowupMail {
    /**
     * メッセージID
     * @type {string}
     * @memberof FollowupMail
     */
    'id'?: string;
    /**
     * メール検索ID
     * @type {string}
     * @memberof FollowupMail
     */
    'templateId': string;
    /**
     * 送信日時
     * @type {string}
     * @memberof FollowupMail
     */
    'sentAt': string;
    /**
     * 会員ID
     * @type {number}
     * @memberof FollowupMail
     */
    'memberId': number;
    /**
     * 
     * @type {FollowupMailStatus}
     * @memberof FollowupMail
     */
    'status': FollowupMailStatus;
    /**
     * 開封日時
     * @type {string}
     * @memberof FollowupMail
     */
    'openedAt'?: string;
    /**
     * 提案物件のリスト
     * @type {Array<MailProperty>}
     * @memberof FollowupMail
     */
    'properties'?: Array<MailProperty>;
}
/**
 * ステータスコード
 * @export
 * @enum {string}
 */

export const FollowupMailStatus = {
    UNSENT: 0,
    SUCCESS: 1,
    DELIVER: 10,
    OPEN: 20,
    CLICK: 30,
    REJECT: 40,
    BOUNCE: 50,
    SPAM: 60,
    RETRY: 70,
    SEND_ERROR: 80,
    FORMAT_ERROR: 90,
    OTHER_ERROR: 99
} as const;

export type FollowupMailStatus = typeof FollowupMailStatus[keyof typeof FollowupMailStatus];


/**
 * FollowupMails
 * @export
 * @interface FollowupMails
 */
export interface FollowupMails {
    /**
     * 配信メール一覧
     * @type {Array<FollowupMail>}
     * @memberof FollowupMails
     */
    'followupMails': Array<FollowupMail>;
}
/**
 * グループコード - 110: 埼玉 - 120: 千葉 - 131: 東京２３区内 - 132: 東京２３区外 - 140: 神奈川 
 * @export
 * @enum {string}
 */

export const GroupCode = {
    SAITAMA: '110',
    CHIBA: '120',
    TOKYO_23_IN: '131',
    TOKYO_23_OUT: '132',
    KANAGAWA: '140'
} as const;

export type GroupCode = typeof GroupCode[keyof typeof GroupCode];


/**
 * 問合せ検索結果
 * @export
 * @interface Inquiries
 */
export interface Inquiries {
    /**
     * 
     * @type {Array<Inquiry>}
     * @memberof Inquiries
     */
    'inquiries'?: Array<Inquiry>;
    /**
     * 総件数
     * @type {number}
     * @memberof Inquiries
     */
    'totalCount'?: number;
}
/**
 * 問合せ情報
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
    /**
     * ID
     * @type {number}
     * @memberof Inquiry
     */
    'id'?: number;
    /**
     * 表示用ID
     * @type {string}
     * @memberof Inquiry
     */
    'displayId'?: string;
    /**
     * 問合せ日時
     * @type {string}
     * @memberof Inquiry
     */
    'inquiredAt'?: string;
    /**
     * AI査定実施日時
     * @type {string}
     * @memberof Inquiry
     */
    'valuatedAt'?: string;
    /**
     * 
     * @type {InquiryType}
     * @memberof Inquiry
     */
    'type': InquiryType;
    /**
     * ご用件
     * @type {string}
     * @memberof Inquiry
     */
    'request': string;
    /**
     * 問合せ内容
     * @type {string}
     * @memberof Inquiry
     */
    'content'?: string;
    /**
     * 名前（姓）
     * @type {string}
     * @memberof Inquiry
     */
    'lastName': string;
    /**
     * 名前（名）
     * @type {string}
     * @memberof Inquiry
     */
    'firstName': string;
    /**
     * ふりがな（姓）
     * @type {string}
     * @memberof Inquiry
     */
    'lastNameKana': string;
    /**
     * ふりがな（名）
     * @type {string}
     * @memberof Inquiry
     */
    'firstNameKana': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Inquiry
     */
    'mailAddress': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Inquiry
     */
    'phoneNumber'?: string;
    /**
     * 会員ID
     * @type {number}
     * @memberof Inquiry
     */
    'memberId'?: number;
    /**
     * AI査定物件ID
     * @type {number}
     * @memberof Inquiry
     */
    'assessmentPropertyId'?: number;
    /**
     * AI査定物件名
     * @type {string}
     * @memberof Inquiry
     */
    'assessmentPropertyName'?: string;
    /**
     * 問合せ物件ID
     * @type {number}
     * @memberof Inquiry
     */
    'propertyId'?: number;
    /**
     * 物件名
     * @type {string}
     * @memberof Inquiry
     */
    'propertyName'?: string;
    /**
     * 
     * @type {InquiryStatus}
     * @memberof Inquiry
     */
    'status'?: InquiryStatus;
    /**
     * 担当者ID
     * @type {string}
     * @memberof Inquiry
     */
    'agentId'?: string;
    /**
     * パートナーID
     * @type {string}
     * @memberof Inquiry
     */
    'partnerId'?: string;
    /**
     * パートナー企業名
     * @type {string}
     * @memberof Inquiry
     */
    'referrerName'?: string;
    /**
     * 備考
     * @type {string}
     * @memberof Inquiry
     */
    'note'?: string;
}
/**
 * 問合せ情報権限
 * @export
 * @enum {string}
 */

export const InquiryAuthority = {
    NO_AUTHORITY: '権限なし',
    VIEW: '閲覧のみ可',
    VIEW_AND_EDIT: '閲覧・編集可'
} as const;

export type InquiryAuthority = typeof InquiryAuthority[keyof typeof InquiryAuthority];


/**
 * お問合せリンク画像
 * @export
 * @interface InquiryImage
 */
export interface InquiryImage {
    /**
     * パス
     * @type {string}
     * @memberof InquiryImage
     */
    's3ObjectKey': string;
}
/**
 * 問合せステータス
 * @export
 * @enum {string}
 */

export const InquiryStatus = {
    BEFORE: '対応前',
    COMPLETE: '完了'
} as const;

export type InquiryStatus = typeof InquiryStatus[keyof typeof InquiryStatus];


/**
 * 問合せ種別
 * @export
 * @enum {string}
 */

export const InquiryType = {
    SELL: '売却一般',
    SELL_AI_PREDICTION: '売却AI査定',
    SELL_FORMALITY_PREDICTION: '売却正式査定',
    BUY: '購入一般',
    BUY_PROPERTY: '購入物件',
    BUY_REPLACEMENT: '買い替え'
} as const;

export type InquiryType = typeof InquiryType[keyof typeof InquiryType];


/**
 * 借入可能金額取得結果
 * @export
 * @interface LoanableAmountsProperty
 */
export interface LoanableAmountsProperty {
    /**
     * 借入可能金額
     * @type {number}
     * @memberof LoanableAmountsProperty
     */
    'loanableAmount'?: number;
    /**
     * 購入可能物件数
     * @type {number}
     * @memberof LoanableAmountsProperty
     */
    'propertiesCount'?: number;
}
/**
 * メール配信結果のリスト
 * @export
 * @interface MailDeliverySummaries
 */
export interface MailDeliverySummaries {
    /**
     * メール配信結果のリスト
     * @type {Array<MailDeliverySummary>}
     * @memberof MailDeliverySummaries
     */
    'mailDeliverySummaries'?: Array<MailDeliverySummary>;
}
/**
 * メール配信結果
 * @export
 * @interface MailDeliverySummary
 */
export interface MailDeliverySummary {
    /**
     * メール配信日
     * @type {string}
     * @memberof MailDeliverySummary
     */
    'deliveryDate'?: string;
    /**
     * 送信数
     * @type {number}
     * @memberof MailDeliverySummary
     */
    'deliveryCount'?: number;
    /**
     * 開封数
     * @type {number}
     * @memberof MailDeliverySummary
     */
    'openCount'?: number;
}
/**
 * メール配信結果権限
 * @export
 * @enum {string}
 */

export const MailDeliverySummaryAuthority = {
    NO_AUTHORITY: '権限なし',
    VIEW: '閲覧可'
} as const;

export type MailDeliverySummaryAuthority = typeof MailDeliverySummaryAuthority[keyof typeof MailDeliverySummaryAuthority];


/**
 * 提案物件情報
 * @export
 * @interface MailProperty
 */
export interface MailProperty {
    /**
     * 物件ID
     * @type {number}
     * @memberof MailProperty
     */
    'propertyId': number;
    /**
     * 物件名
     * @type {string}
     * @memberof MailProperty
     */
    'propertyName': string;
    /**
     * 住所
     * @type {string}
     * @memberof MailProperty
     */
    'address': string;
    /**
     * 価格
     * @type {number}
     * @memberof MailProperty
     */
    'price': number;
    /**
     * 占有面積
     * @type {number}
     * @memberof MailProperty
     */
    'unitSize': number;
    /**
     * 間取り
     * @type {string}
     * @memberof MailProperty
     */
    'floorPlan': string;
    /**
     * 階数
     * @type {number}
     * @memberof MailProperty
     */
    'story': number;
}
/**
 * メールテンプレート権限
 * @export
 * @enum {string}
 */

export const MailTemplateAuthority = {
    NO_AUTHORITY: '権限なし',
    VIEW: '閲覧のみ可',
    VIEW_AND_EDIT: '閲覧・編集可'
} as const;

export type MailTemplateAuthority = typeof MailTemplateAuthority[keyof typeof MailTemplateAuthority];


/**
 * 管理方式
 * @export
 * @enum {string}
 */

export const MaintenanceType = {
    RESIDENT: '常駐',
    DAY_SHIFT: '日勤',
    ROUND: '巡回',
    SELF_MANAGEMENT: '自主管理'
} as const;

export type MaintenanceType = typeof MaintenanceType[keyof typeof MaintenanceType];


/**
 * Doorman Mansion
 * @export
 * @interface Mansion
 */
export interface Mansion extends DoormanProperty {
    /**
     * 
     * @type {DoormanAddress}
     * @memberof Mansion
     */
    'address': DoormanAddress;
    /**
     * 
     * @type {DoormanTraffic}
     * @memberof Mansion
     */
    'traffic': DoormanTraffic;
    /**
     * 物件名
     * @type {string}
     * @memberof Mansion
     */
    'name': string;
    /**
     * 築年月
     * @type {string}
     * @memberof Mansion
     */
    'builtDate': string;
    /**
     * 建物構造
     * @type {string}
     * @memberof Mansion
     */
    'structure'?: string;
    /**
     * 総戸数
     * @type {number}
     * @memberof Mansion
     */
    'totalUnitCount'?: number;
    /**
     * 棟総戸数
     * @type {number}
     * @memberof Mansion
     */
    'buildingTotalUnitCount'?: number;
    /**
     * 地上階層
     * @type {number}
     * @memberof Mansion
     */
    'totalStory'?: number;
    /**
     * 用途地域
     * @type {string}
     * @memberof Mansion
     */
    'useDistrict'?: string;
    /**
     * 土地権利
     * @type {string}
     * @memberof Mansion
     */
    'landRights'?: string;
    /**
     * 施主
     * @type {string}
     * @memberof Mansion
     */
    'owner'?: string;
    /**
     * 分譲会社
     * @type {string}
     * @memberof Mansion
     */
    'developmentCompany'?: string;
    /**
     * 施工会社
     * @type {string}
     * @memberof Mansion
     */
    'builtCompany'?: string;
    /**
     * 管理会社
     * @type {string}
     * @memberof Mansion
     */
    'maintenanceCompany'?: string;
    /**
     * 管理人状況
     * @type {string}
     * @memberof Mansion
     */
    'maintenanceType'?: string;
    /**
     * 管理形態
     * @type {string}
     * @memberof Mansion
     */
    'maintenanceStyle'?: string;
}
/**
 * Doorman Mansion データ
 * @export
 * @interface MansionAttributes
 */
export interface MansionAttributes {
    /**
     * 
     * @type {DoormanAddress}
     * @memberof MansionAttributes
     */
    'address'?: DoormanAddress;
    /**
     * 
     * @type {DoormanTraffic}
     * @memberof MansionAttributes
     */
    'traffic'?: DoormanTraffic;
    /**
     * 物件名
     * @type {string}
     * @memberof MansionAttributes
     */
    'name'?: string;
    /**
     * 築年月
     * @type {string}
     * @memberof MansionAttributes
     */
    'builtDate'?: string;
    /**
     * 建物構造
     * @type {string}
     * @memberof MansionAttributes
     */
    'structure'?: string;
    /**
     * 総戸数
     * @type {number}
     * @memberof MansionAttributes
     */
    'totalUnitCount'?: number;
    /**
     * 棟総戸数
     * @type {number}
     * @memberof MansionAttributes
     */
    'buildingTotalUnitCount'?: number;
    /**
     * 地上階層
     * @type {number}
     * @memberof MansionAttributes
     */
    'totalStory'?: number;
    /**
     * 用途地域
     * @type {string}
     * @memberof MansionAttributes
     */
    'useDistrict'?: string;
    /**
     * 土地権利
     * @type {string}
     * @memberof MansionAttributes
     */
    'landRights'?: string;
    /**
     * 施主
     * @type {string}
     * @memberof MansionAttributes
     */
    'owner'?: string;
    /**
     * 分譲会社
     * @type {string}
     * @memberof MansionAttributes
     */
    'developmentCompany'?: string;
    /**
     * 施工会社
     * @type {string}
     * @memberof MansionAttributes
     */
    'builtCompany'?: string;
    /**
     * 管理会社
     * @type {string}
     * @memberof MansionAttributes
     */
    'maintenanceCompany'?: string;
    /**
     * 管理人状況
     * @type {string}
     * @memberof MansionAttributes
     */
    'maintenanceType'?: string;
    /**
     * 管理形態
     * @type {string}
     * @memberof MansionAttributes
     */
    'maintenanceStyle'?: string;
}
/**
 * 面積計測方式
 * @export
 * @enum {string}
 */

export const MeasurementMethod = {
    CENTER_LINE_OF_WALL: '壁芯',
    REGISTRATION: '登記'
} as const;

export type MeasurementMethod = typeof MeasurementMethod[keyof typeof MeasurementMethod];


/**
 * 会員情報
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * ID
     * @type {number}
     * @memberof Member
     */
    'id'?: number;
    /**
     * 表示用ID
     * @type {string}
     * @memberof Member
     */
    'displayId'?: string;
    /**
     * お名前(姓)
     * @type {string}
     * @memberof Member
     */
    'lastName': string;
    /**
     * お名前(名)
     * @type {string}
     * @memberof Member
     */
    'firstName': string;
    /**
     * ふりがな(姓)
     * @type {string}
     * @memberof Member
     */
    'lastNameKana': string;
    /**
     * ふりがな(名)
     * @type {string}
     * @memberof Member
     */
    'firstNameKana': string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Member
     */
    'mailAddress': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Member
     */
    'phoneNumber'?: string;
    /**
     * 年収
     * @type {number}
     * @memberof Member
     */
    'annualIncome': number;
    /**
     * 生年月日
     * @type {string}
     * @memberof Member
     */
    'birthday': string;
    /**
     * 総借入金額
     * @type {number}
     * @memberof Member
     */
    'totalLoanAmount': number;
    /**
     * 勤務先
     * @type {string}
     * @memberof Member
     */
    'workPlace'?: string;
    /**
     * 勤続年数
     * @type {number}
     * @memberof Member
     */
    'serviceYears'?: number;
    /**
     * メール配信
     * @type {boolean}
     * @memberof Member
     */
    'mailDelivery'?: boolean;
    /**
     * 登録ステータス
     * @type {boolean}
     * @memberof Member
     */
    'isRegistered'?: boolean;
    /**
     * 備考
     * @type {string}
     * @memberof Member
     */
    'note'?: string;
    /**
     * 識別ID
     * @type {string}
     * @memberof Member
     */
    'awsCognitoId'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof Member
     */
    'createdAt'?: string;
    /**
     * 更新日
     * @type {string}
     * @memberof Member
     */
    'updatedAt'?: string;
    /**
     * 借入可能額
     * @type {number}
     * @memberof Member
     */
    'loanableAmount'?: number;
    /**
     * 購入可能物件数
     * @type {number}
     * @memberof Member
     */
    'propertiesCount'?: number;
    /**
     * 購入者に関連付けられたパートナー ID
     * @type {string}
     * @memberof Member
     */
    'partnerId'?: string;
    /**
     * 購入者に関連付けられたパートナー名
     * @type {string}
     * @memberof Member
     */
    'partnerName'?: string;
    /**
     * ユーザーのIPアドレス
     * @type {string}
     * @memberof Member
     */
    'ipAddress'?: string;
}
/**
 * 買主会員情報権限
 * @export
 * @enum {string}
 */

export const MemberAuthority = {
    NO_AUTHORITY: '権限なし',
    VIEW: '閲覧のみ可',
    VIEW_AND_EDIT: '閲覧・編集可'
} as const;

export type MemberAuthority = typeof MemberAuthority[keyof typeof MemberAuthority];


/**
 * 会員情報リスト
 * @export
 * @interface Members
 */
export interface Members {
    /**
     * 会員情報一覧
     * @type {Array<Member>}
     * @memberof Members
     */
    'members'?: Array<Member>;
    /**
     * 総件数
     * @type {number}
     * @memberof Members
     */
    'totalCount'?: number;
}
/**
 * 査定額マイナス調整値
 * @export
 * @interface NegativeAdjustment
 */
export interface NegativeAdjustment {
    /**
     * ID
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'id'?: number;
    /**
     * 名前
     * @type {string}
     * @memberof NegativeAdjustment
     */
    'name'?: string;
    /**
     * 築１年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within1Year'?: number;
    /**
     * 築５年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within5Years'?: number;
    /**
     * 築１０年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within10Years'?: number;
    /**
     * 築１５年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within15Years'?: number;
    /**
     * 築２０年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within20Years'?: number;
    /**
     * 築２５年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within25Years'?: number;
    /**
     * 築３０年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within30Years'?: number;
    /**
     * 築３５年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within35Years'?: number;
    /**
     * 築４０年以内
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'within40Years'?: number;
    /**
     * 築４１年以上
     * @type {number}
     * @memberof NegativeAdjustment
     */
    'over40Years'?: number;
}
/**
 * 査定額マイナス調整値リスト
 * @export
 * @interface NegativeAdjustments
 */
export interface NegativeAdjustments {
    /**
     * 
     * @type {Array<NegativeAdjustment>}
     * @memberof NegativeAdjustments
     */
    'negativeAdjustments'?: Array<NegativeAdjustment>;
}
/**
 * 次回更新予定期間　種類 - ヶ月 - 日 
 * @export
 * @enum {string}
 */

export const NextUpdatePeriodUnit = {
    MONTHLY: 'ヶ月',
    DAILY: '日'
} as const;

export type NextUpdatePeriodUnit = typeof NextUpdatePeriodUnit[keyof typeof NextUpdatePeriodUnit];


/**
 * 事業所
 * @export
 * @interface Office
 */
export interface Office {
    /**
     * ID
     * @type {number}
     * @memberof Office
     */
    'id'?: number;
    /**
     * 支店名
     * @type {string}
     * @memberof Office
     */
    'branchOfficeName': string;
    /**
     * 組織ID
     * @type {number}
     * @memberof Office
     */
    'organizationId'?: number;
    /**
     * 組織名
     * @type {string}
     * @memberof Office
     */
    'organizationName'?: string;
    /**
     * 営業開始時間
     * @type {string}
     * @memberof Office
     */
    'businessStartHours': string;
    /**
     * 営業終了時間
     * @type {string}
     * @memberof Office
     */
    'businessEndHours': string;
    /**
     * 定休日
     * @type {string}
     * @memberof Office
     */
    'regularHoliday': string;
    /**
     * 所在地
     * @type {string}
     * @memberof Office
     */
    'address': string;
    /**
     * 宅建免許番号
     * @type {string}
     * @memberof Office
     */
    'realEstateLicenseNumber': string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Office
     */
    'phoneNumber': string;
}
/**
 * 事業所リスト
 * @export
 * @interface Offices
 */
export interface Offices {
    /**
     * 事業所情報一覧
     * @type {Array<Office>}
     * @memberof Offices
     */
    'offices'?: Array<Office>;
    /**
     * 総件数
     * @type {number}
     * @memberof Offices
     */
    'totalCount'?: number;
}
/**
 * 組織
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * ID
     * @type {number}
     * @memberof Organization
     */
    'id'?: number;
    /**
     * 組織名
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * Fromアドレス
     * @type {string}
     * @memberof Organization
     */
    'fromAddress'?: string;
    /**
     * ロゴ画像
     * @type {string}
     * @memberof Organization
     */
    'logoImagePath'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof Organization
     */
    'createdAt'?: string;
    /**
     * 更新日
     * @type {string}
     * @memberof Organization
     */
    'updatedAt'?: string;
}
/**
 * パートナー企業
 * @export
 * @interface Partner
 */
export interface Partner {
    /**
     * ID
     * @type {string}
     * @memberof Partner
     */
    'id'?: string;
    /**
     * 企業名
     * @type {string}
     * @memberof Partner
     */
    'name'?: string;
}
/**
 * パートナー企業リスト
 * @export
 * @interface Partners
 */
export interface Partners {
    /**
     * パートナー企業情報一覧
     * @type {Array<Partner>}
     * @memberof Partners
     */
    'partners'?: Array<Partner>;
    /**
     * 総件数
     * @type {number}
     * @memberof Partners
     */
    'totalCount'?: number;
}
/**
 * 物件リスト
 * @export
 * @interface Properties
 */
export interface Properties {
    /**
     * 物件情報一覧
     * @type {Array<Property>}
     * @memberof Properties
     */
    'properties'?: Array<Property>;
    /**
     * 総件数
     * @type {number}
     * @memberof Properties
     */
    'totalCount'?: number;
}
/**
 * 物件情報
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {VisibilityStatus}
     * @memberof Property
     */
    'visibilityStatus': VisibilityStatus;
    /**
     * ID
     * @type {number}
     * @memberof Property
     */
    'id'?: number;
    /**
     * 表示用ID
     * @type {string}
     * @memberof Property
     */
    'displayId'?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof Property
     */
    'prefectureName'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof Property
     */
    'cityName'?: string;
    /**
     * 町丁目
     * @type {string}
     * @memberof Property
     */
    'townName'?: string;
    /**
     * 路線名
     * @type {string}
     * @memberof Property
     */
    'lineName'?: string;
    /**
     * 駅名
     * @type {string}
     * @memberof Property
     */
    'stationName'?: string;
    /**
     * 駅徒歩分
     * @type {number}
     * @memberof Property
     */
    'walkMinute'?: number;
    /**
     * マンション名
     * @type {string}
     * @memberof Property
     */
    'mansionName'?: string;
    /**
     * 建物階数
     * @type {number}
     * @memberof Property
     */
    'totalStory'?: number;
    /**
     * 築年月
     * @type {string}
     * @memberof Property
     */
    'builtDate'?: string;
    /**
     * 
     * @type {Structure}
     * @memberof Property
     */
    'structure'?: Structure;
    /**
     * 総戸数
     * @type {number}
     * @memberof Property
     */
    'totalUnit'?: number;
    /**
     * 土地権利
     * @type {string}
     * @memberof Property
     */
    'landRight'?: string;
    /**
     * マンションID
     * @type {string}
     * @memberof Property
     */
    'mansionId'?: string;
    /**
     * 部屋番号
     * @type {string}
     * @memberof Property
     */
    'roomNumber'?: string;
    /**
     * 間取り
     * @type {string}
     * @memberof Property
     */
    'floorPlan'?: string;
    /**
     * 所在階
     * @type {number}
     * @memberof Property
     */
    'story'?: number;
    /**
     * 専有面積
     * @type {number}
     * @memberof Property
     */
    'unitSize'?: number;
    /**
     * バルコニー面積
     * @type {number}
     * @memberof Property
     */
    'balconySize'?: number;
    /**
     * 
     * @type {TenancyStatus}
     * @memberof Property
     */
    'tenancyStatus'?: TenancyStatus;
    /**
     * 
     * @type {RentManagementStatus}
     * @memberof Property
     */
    'rentManagementStatus'?: RentManagementStatus;
    /**
     * 
     * @type {RentType}
     * @memberof Property
     */
    'rentType'?: RentType;
    /**
     * 賃料
     * @type {number}
     * @memberof Property
     */
    'rentPrice'?: number;
    /**
     * 賃貸管理手数料
     * @type {number}
     * @memberof Property
     */
    'rentManagementFee'?: number;
    /**
     * 賃貸管理会社
     * @type {string}
     * @memberof Property
     */
    'rentManagementCompany'?: string;
    /**
     * 管理費
     * @type {number}
     * @memberof Property
     */
    'maintenanceFee'?: number;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof Property
     */
    'repairingFee'?: number;
    /**
     * 建物管理会社
     * @type {string}
     * @memberof Property
     */
    'buildingManagementCompany'?: string;
    /**
     * 
     * @type {MaintenanceType}
     * @memberof Property
     */
    'maintenanceType'?: MaintenanceType;
    /**
     * 販売価格
     * @type {number}
     * @memberof Property
     */
    'salesPrice'?: number;
    /**
     * 
     * @type {TransactionType}
     * @memberof Property
     */
    'transactionType'?: TransactionType;
    /**
     * 担当者ID
     * @type {string}
     * @memberof Property
     */
    'agentId'?: string;
    /**
     * 担当者名
     * @type {string}
     * @memberof Property
     */
    'agentName'?: string;
    /**
     * キャッチコピー
     * @type {string}
     * @memberof Property
     */
    'catchCopy'?: string;
    /**
     * コメント
     * @type {string}
     * @memberof Property
     */
    'description'?: string;
    /**
     * 
     * @type {PropertyStatus}
     * @memberof Property
     */
    'propertyStatus'?: PropertyStatus;
    /**
     * 新着フラグ
     * @type {boolean}
     * @memberof Property
     */
    'isNew'?: boolean;
    /**
     * 価格変更フラグ
     * @type {boolean}
     * @memberof Property
     */
    'hasPriceChanged'?: boolean;
    /**
     * 成約日
     * @type {string}
     * @memberof Property
     */
    'contractDate'?: string;
    /**
     * 成約価格
     * @type {number}
     * @memberof Property
     */
    'contractPrice'?: number;
    /**
     * 備考
     * @type {string}
     * @memberof Property
     */
    'note'?: string;
    /**
     * 
     * @type {Office}
     * @memberof Property
     */
    'office'?: Office;
    /**
     * 掲載画像
     * @type {Array<PropertyImage>}
     * @memberof Property
     */
    'propertyImages'?: Array<PropertyImage>;
    /**
     * 
     * @type {RevenueSimulation}
     * @memberof Property
     */
    'simulation'?: RevenueSimulation;
    /**
     * 初回掲載日
     * @type {string}
     * @memberof Property
     */
    'firstPublishedAt'?: string;
    /**
     * 価格変更日
     * @type {string}
     * @memberof Property
     */
    'priceUpdatedAt'?: string;
    /**
     * 次回情報更新日
     * @type {string}
     * @memberof Property
     */
    'nextUpdateDate'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof Property
     */
    'createdAt'?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Property
     */
    'updatedAt'?: string;
}
/**
 * エリア
 * @export
 * @enum {string}
 */

export const PropertyArea = {
    SIX_DISTRICT: '都心６区',
    EAST_CASTLE: '城東',
    WEST_CASTLE: '城西',
    SOUTH_CASTLE: '城南',
    NORTH_CASTLE: '城北',
    OUTSIDE_23_WARDS: '２３区外',
    KANAGAWA: '神奈川県',
    SAITAMA: '埼玉県',
    CHIBA: '千葉県'
} as const;

export type PropertyArea = typeof PropertyArea[keyof typeof PropertyArea];


/**
 * 物件情報属性
 * @export
 * @interface PropertyAttr
 */
export interface PropertyAttr {
    /**
     * ID
     * @type {number}
     * @memberof PropertyAttr
     */
    'id'?: number;
    /**
     * 表示用ID
     * @type {string}
     * @memberof PropertyAttr
     */
    'displayId'?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof PropertyAttr
     */
    'prefectureName'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof PropertyAttr
     */
    'cityName'?: string;
    /**
     * 町丁目
     * @type {string}
     * @memberof PropertyAttr
     */
    'townName'?: string;
    /**
     * 路線名
     * @type {string}
     * @memberof PropertyAttr
     */
    'lineName'?: string;
    /**
     * 駅名
     * @type {string}
     * @memberof PropertyAttr
     */
    'stationName'?: string;
    /**
     * 駅徒歩分
     * @type {number}
     * @memberof PropertyAttr
     */
    'walkMinute'?: number;
    /**
     * マンション名
     * @type {string}
     * @memberof PropertyAttr
     */
    'mansionName'?: string;
    /**
     * 建物階数
     * @type {number}
     * @memberof PropertyAttr
     */
    'totalStory'?: number;
    /**
     * 築年月
     * @type {string}
     * @memberof PropertyAttr
     */
    'builtDate'?: string;
    /**
     * 
     * @type {Structure}
     * @memberof PropertyAttr
     */
    'structure'?: Structure;
    /**
     * 総戸数
     * @type {number}
     * @memberof PropertyAttr
     */
    'totalUnit'?: number;
    /**
     * 土地権利
     * @type {string}
     * @memberof PropertyAttr
     */
    'landRight'?: string;
    /**
     * マンションID
     * @type {string}
     * @memberof PropertyAttr
     */
    'mansionId'?: string;
    /**
     * 部屋番号
     * @type {string}
     * @memberof PropertyAttr
     */
    'roomNumber'?: string;
    /**
     * 間取り
     * @type {string}
     * @memberof PropertyAttr
     */
    'floorPlan'?: string;
    /**
     * 所在階
     * @type {number}
     * @memberof PropertyAttr
     */
    'story'?: number;
    /**
     * 専有面積
     * @type {number}
     * @memberof PropertyAttr
     */
    'unitSize'?: number;
    /**
     * バルコニー面積
     * @type {number}
     * @memberof PropertyAttr
     */
    'balconySize'?: number;
    /**
     * 
     * @type {TenancyStatus}
     * @memberof PropertyAttr
     */
    'tenancyStatus'?: TenancyStatus;
    /**
     * 
     * @type {RentManagementStatus}
     * @memberof PropertyAttr
     */
    'rentManagementStatus'?: RentManagementStatus;
    /**
     * 
     * @type {RentType}
     * @memberof PropertyAttr
     */
    'rentType'?: RentType;
    /**
     * 賃料
     * @type {number}
     * @memberof PropertyAttr
     */
    'rentPrice'?: number;
    /**
     * 賃貸管理手数料
     * @type {number}
     * @memberof PropertyAttr
     */
    'rentManagementFee'?: number;
    /**
     * 賃貸管理会社
     * @type {string}
     * @memberof PropertyAttr
     */
    'rentManagementCompany'?: string;
    /**
     * 管理費
     * @type {number}
     * @memberof PropertyAttr
     */
    'maintenanceFee'?: number;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof PropertyAttr
     */
    'repairingFee'?: number;
    /**
     * 建物管理会社
     * @type {string}
     * @memberof PropertyAttr
     */
    'buildingManagementCompany'?: string;
    /**
     * 
     * @type {MaintenanceType}
     * @memberof PropertyAttr
     */
    'maintenanceType'?: MaintenanceType;
    /**
     * 販売価格
     * @type {number}
     * @memberof PropertyAttr
     */
    'salesPrice'?: number;
    /**
     * 
     * @type {TransactionType}
     * @memberof PropertyAttr
     */
    'transactionType'?: TransactionType;
    /**
     * 担当者ID
     * @type {string}
     * @memberof PropertyAttr
     */
    'agentId'?: string;
    /**
     * 担当者名
     * @type {string}
     * @memberof PropertyAttr
     */
    'agentName'?: string;
    /**
     * キャッチコピー
     * @type {string}
     * @memberof PropertyAttr
     */
    'catchCopy'?: string;
    /**
     * コメント
     * @type {string}
     * @memberof PropertyAttr
     */
    'description'?: string;
    /**
     * 
     * @type {PropertyStatus}
     * @memberof PropertyAttr
     */
    'propertyStatus'?: PropertyStatus;
    /**
     * 新着フラグ
     * @type {boolean}
     * @memberof PropertyAttr
     */
    'isNew'?: boolean;
    /**
     * 価格変更フラグ
     * @type {boolean}
     * @memberof PropertyAttr
     */
    'hasPriceChanged'?: boolean;
    /**
     * 成約日
     * @type {string}
     * @memberof PropertyAttr
     */
    'contractDate'?: string;
    /**
     * 成約価格
     * @type {number}
     * @memberof PropertyAttr
     */
    'contractPrice'?: number;
    /**
     * 備考
     * @type {string}
     * @memberof PropertyAttr
     */
    'note'?: string;
    /**
     * 
     * @type {Office}
     * @memberof PropertyAttr
     */
    'office'?: Office;
    /**
     * 掲載画像
     * @type {Array<PropertyImage>}
     * @memberof PropertyAttr
     */
    'propertyImages'?: Array<PropertyImage>;
    /**
     * 
     * @type {RevenueSimulation}
     * @memberof PropertyAttr
     */
    'simulation'?: RevenueSimulation;
    /**
     * 初回掲載日
     * @type {string}
     * @memberof PropertyAttr
     */
    'firstPublishedAt'?: string;
    /**
     * 価格変更日
     * @type {string}
     * @memberof PropertyAttr
     */
    'priceUpdatedAt'?: string;
    /**
     * 次回情報更新日
     * @type {string}
     * @memberof PropertyAttr
     */
    'nextUpdateDate'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof PropertyAttr
     */
    'createdAt'?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof PropertyAttr
     */
    'updatedAt'?: string;
}
/**
 * 物件情報権限
 * @export
 * @enum {string}
 */

export const PropertyAuthority = {
    NO_AUTHORITY: '権限なし',
    VIEW: '閲覧のみ可',
    VIEW_AND_EDIT: '閲覧・編集可'
} as const;

export type PropertyAuthority = typeof PropertyAuthority[keyof typeof PropertyAuthority];


/**
 * 掲載画像
 * @export
 * @interface PropertyImage
 */
export interface PropertyImage {
    /**
     * 掲載画像ID
     * @type {number}
     * @memberof PropertyImage
     */
    'id': number;
    /**
     * パス
     * @type {string}
     * @memberof PropertyImage
     */
    's3ObjectKey'?: string;
    /**
     * 表示順
     * @type {number}
     * @memberof PropertyImage
     */
    'displayOrder': number;
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof PropertyImage
     */
    'visibility': boolean;
    /**
     * デフォルト画像
     * @type {boolean}
     * @memberof PropertyImage
     */
    'isPreset'?: boolean;
}
/**
 * 物件取得並び順
 * @export
 * @enum {string}
 */

export const PropertySort = {
    NEW_ARRIVAL_DESC: '新着順',
    SALES_PRICE_DESC: '価格の高い順',
    SALES_PRICE_ASC: '価格の安い順',
    BUILT_DATE_DESC: '築年数の古い順',
    BUILT_DATE_ASC: '築年数の新しい順',
    WALK_MINUTE_DESC: '駅徒歩が遠い順',
    WALK_MINUTE_ASC: '駅徒歩が近い順'
} as const;

export type PropertySort = typeof PropertySort[keyof typeof PropertySort];


/**
 * 物件ステータス
 * @export
 * @enum {string}
 */

export const PropertyStatus = {
    BEFORE_SALE: '売出前',
    SALE: '売出中',
    SOLD: '成約済',
    CANCEL: '取下げ'
} as const;

export type PropertyStatus = typeof PropertyStatus[keyof typeof PropertyStatus];


/**
 * 物件種別
 * @export
 * @enum {string}
 */

export const PropertyType = {
    Mansion: 'mansion',
    Unit: 'unit'
} as const;

export type PropertyType = typeof PropertyType[keyof typeof PropertyType];


/**
 * 提案物件情報のリスト
 * @export
 * @interface ProposedProperties
 */
export interface ProposedProperties {
    /**
     * 提案物件情報のリスト
     * @type {Array<ProposedProperty>}
     * @memberof ProposedProperties
     */
    'proposedProperties'?: Array<ProposedProperty>;
    /**
     * 総件数
     * @type {number}
     * @memberof ProposedProperties
     */
    'totalCount'?: number;
}
/**
 * 提案物件情報
 * @export
 * @interface ProposedProperty
 */
export interface ProposedProperty {
    /**
     * 提案日
     * @type {string}
     * @memberof ProposedProperty
     */
    'date'?: string;
    /**
     * 物件ID
     * @type {number}
     * @memberof ProposedProperty
     */
    'propertyId'?: number;
    /**
     * 物件名
     * @type {string}
     * @memberof ProposedProperty
     */
    'propertyName'?: string;
    /**
     * 住所
     * @type {string}
     * @memberof ProposedProperty
     */
    'address'?: string;
    /**
     * 価格
     * @type {number}
     * @memberof ProposedProperty
     */
    'price'?: number;
    /**
     * 占有面積
     * @type {number}
     * @memberof ProposedProperty
     */
    'unitSize'?: number;
    /**
     * 間取り
     * @type {string}
     * @memberof ProposedProperty
     */
    'floorPlan'?: string;
    /**
     * 階数
     * @type {number}
     * @memberof ProposedProperty
     */
    'story'?: number;
}
/**
 * 公開物件情報
 * @export
 * @interface PublishedProperty
 */
export interface PublishedProperty extends Property {
    /**
     * ID
     * @type {number}
     * @memberof PublishedProperty
     */
    'id'?: number;
    /**
     * 表示用ID
     * @type {string}
     * @memberof PublishedProperty
     */
    'displayId'?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof PublishedProperty
     */
    'prefectureName': string;
    /**
     * 市区町村
     * @type {string}
     * @memberof PublishedProperty
     */
    'cityName': string;
    /**
     * 町丁目
     * @type {string}
     * @memberof PublishedProperty
     */
    'townName': string;
    /**
     * 路線名
     * @type {string}
     * @memberof PublishedProperty
     */
    'lineName': string;
    /**
     * 駅名
     * @type {string}
     * @memberof PublishedProperty
     */
    'stationName': string;
    /**
     * 駅徒歩分
     * @type {number}
     * @memberof PublishedProperty
     */
    'walkMinute': number;
    /**
     * マンション名
     * @type {string}
     * @memberof PublishedProperty
     */
    'mansionName': string;
    /**
     * 建物階数
     * @type {number}
     * @memberof PublishedProperty
     */
    'totalStory': number;
    /**
     * 築年月
     * @type {string}
     * @memberof PublishedProperty
     */
    'builtDate': string;
    /**
     * 
     * @type {Structure}
     * @memberof PublishedProperty
     */
    'structure': Structure;
    /**
     * 総戸数
     * @type {number}
     * @memberof PublishedProperty
     */
    'totalUnit': number;
    /**
     * 土地権利
     * @type {string}
     * @memberof PublishedProperty
     */
    'landRight': string;
    /**
     * マンションID
     * @type {string}
     * @memberof PublishedProperty
     */
    'mansionId'?: string;
    /**
     * 部屋番号
     * @type {string}
     * @memberof PublishedProperty
     */
    'roomNumber'?: string;
    /**
     * 間取り
     * @type {string}
     * @memberof PublishedProperty
     */
    'floorPlan': string;
    /**
     * 所在階
     * @type {number}
     * @memberof PublishedProperty
     */
    'story': number;
    /**
     * 専有面積
     * @type {number}
     * @memberof PublishedProperty
     */
    'unitSize': number;
    /**
     * バルコニー面積
     * @type {number}
     * @memberof PublishedProperty
     */
    'balconySize': number;
    /**
     * 
     * @type {TenancyStatus}
     * @memberof PublishedProperty
     */
    'tenancyStatus': TenancyStatus;
    /**
     * 
     * @type {RentManagementStatus}
     * @memberof PublishedProperty
     */
    'rentManagementStatus'?: RentManagementStatus;
    /**
     * 
     * @type {RentType}
     * @memberof PublishedProperty
     */
    'rentType'?: RentType;
    /**
     * 賃料
     * @type {number}
     * @memberof PublishedProperty
     */
    'rentPrice': number;
    /**
     * 賃貸管理手数料
     * @type {number}
     * @memberof PublishedProperty
     */
    'rentManagementFee': number;
    /**
     * 賃貸管理会社
     * @type {string}
     * @memberof PublishedProperty
     */
    'rentManagementCompany': string;
    /**
     * 管理費
     * @type {number}
     * @memberof PublishedProperty
     */
    'maintenanceFee': number;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof PublishedProperty
     */
    'repairingFee': number;
    /**
     * 建物管理会社
     * @type {string}
     * @memberof PublishedProperty
     */
    'buildingManagementCompany'?: string;
    /**
     * 
     * @type {MaintenanceType}
     * @memberof PublishedProperty
     */
    'maintenanceType': MaintenanceType;
    /**
     * 販売価格
     * @type {number}
     * @memberof PublishedProperty
     */
    'salesPrice': number;
    /**
     * 
     * @type {TransactionType}
     * @memberof PublishedProperty
     */
    'transactionType': TransactionType;
    /**
     * 担当者ID
     * @type {string}
     * @memberof PublishedProperty
     */
    'agentId': string;
    /**
     * 担当者名
     * @type {string}
     * @memberof PublishedProperty
     */
    'agentName'?: string;
    /**
     * キャッチコピー
     * @type {string}
     * @memberof PublishedProperty
     */
    'catchCopy': string;
    /**
     * コメント
     * @type {string}
     * @memberof PublishedProperty
     */
    'description'?: string;
    /**
     * 
     * @type {PropertyStatus}
     * @memberof PublishedProperty
     */
    'propertyStatus'?: PropertyStatus;
    /**
     * 新着フラグ
     * @type {boolean}
     * @memberof PublishedProperty
     */
    'isNew'?: boolean;
    /**
     * 価格変更フラグ
     * @type {boolean}
     * @memberof PublishedProperty
     */
    'hasPriceChanged'?: boolean;
    /**
     * 成約日
     * @type {string}
     * @memberof PublishedProperty
     */
    'contractDate'?: string;
    /**
     * 成約価格
     * @type {number}
     * @memberof PublishedProperty
     */
    'contractPrice'?: number;
    /**
     * 備考
     * @type {string}
     * @memberof PublishedProperty
     */
    'note'?: string;
    /**
     * 
     * @type {Office}
     * @memberof PublishedProperty
     */
    'office'?: Office;
    /**
     * 掲載画像
     * @type {Array<PropertyImage>}
     * @memberof PublishedProperty
     */
    'propertyImages'?: Array<PropertyImage>;
    /**
     * 
     * @type {RevenueSimulation}
     * @memberof PublishedProperty
     */
    'simulation'?: RevenueSimulation;
    /**
     * 初回掲載日
     * @type {string}
     * @memberof PublishedProperty
     */
    'firstPublishedAt'?: string;
    /**
     * 価格変更日
     * @type {string}
     * @memberof PublishedProperty
     */
    'priceUpdatedAt'?: string;
    /**
     * 次回情報更新日
     * @type {string}
     * @memberof PublishedProperty
     */
    'nextUpdateDate'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof PublishedProperty
     */
    'createdAt'?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof PublishedProperty
     */
    'updatedAt'?: string;
}
/**
 * 賃貸管理手数料（種類）
 * @export
 * @enum {string}
 */

export const RentManagementFeeType = {
    PERCENT: '％',
    YEN: '円'
} as const;

export type RentManagementFeeType = typeof RentManagementFeeType[keyof typeof RentManagementFeeType];


/**
 * 賃貸管理状況
 * @export
 * @enum {string}
 */

export const RentManagementStatus = {
    SUBLEASE: 'サブリース',
    OTHER: 'サブリース以外'
} as const;

export type RentManagementStatus = typeof RentManagementStatus[keyof typeof RentManagementStatus];


/**
 * 賃料種類
 * @export
 * @enum {string}
 */

export const RentType = {
    RESIDENT: '入居者支払い賃料',
    NET_INCOME: '手取り賃料',
    RECRUIT: '募集家賃',
    AI_ASSESSMENT: 'AI査定賃料'
} as const;

export type RentType = typeof RentType[keyof typeof RentType];


/**
 * 担当者情報
 * @export
 * @interface ResendRequest
 */
export interface ResendRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof ResendRequest
     */
    'mailAddress': string;
}
/**
 * メールアドレス登録確認
 * @export
 * @interface ResponseRegisterMailAddress
 */
export interface ResponseRegisterMailAddress {
    /**
     * 登録してるかどうか
     * @type {boolean}
     * @memberof ResponseRegisterMailAddress
     */
    'isRegistered'?: boolean;
}
/**
 * 収益シミュレーション
 * @export
 * @interface RevenueSimulation
 */
export interface RevenueSimulation {
    /**
     * 物件価格
     * @type {number}
     * @memberof RevenueSimulation
     */
    'price'?: number;
    /**
     * 頭金
     * @type {number}
     * @memberof RevenueSimulation
     */
    'downPayment'?: number;
    /**
     * ローン額
     * @type {number}
     * @memberof RevenueSimulation
     */
    'loanPrice'?: number;
    /**
     * 金利
     * @type {number}
     * @memberof RevenueSimulation
     */
    'interestRate'?: number;
    /**
     * 返済期間
     * @type {number}
     * @memberof RevenueSimulation
     */
    'repaymentPeriod'?: number;
    /**
     * 賃料
     * @type {number}
     * @memberof RevenueSimulation
     */
    'rentPrice'?: number;
    /**
     * 賃貸管理手数料
     * @type {number}
     * @memberof RevenueSimulation
     */
    'rentManagementFee'?: number;
    /**
     * ローン返済額
     * @type {number}
     * @memberof RevenueSimulation
     */
    'loanRepaymentPrice'?: number;
    /**
     * 管理費
     * @type {number}
     * @memberof RevenueSimulation
     */
    'maintenanceFee'?: number;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof RevenueSimulation
     */
    'repairingFee'?: number;
    /**
     * 月収支
     * @type {number}
     * @memberof RevenueSimulation
     */
    'monthlyBalancePayment'?: number;
}
/**
 * SalesProperties
 * @export
 * @interface SalesProperties
 */
export interface SalesProperties {
    /**
     * 売出物件一覧
     * @type {Array<Property>}
     * @memberof SalesProperties
     */
    'salesProperties': Array<Property>;
    /**
     * 売出物件件数
     * @type {number}
     * @memberof SalesProperties
     */
    'salesPropertiesCount'?: number;
}
/**
 * 成約済物件表示設定 - ヶ月 - 日 - 件 
 * @export
 * @enum {string}
 */

export const SamePropertyConditionUnit = {
    MONTHLY: 'ヶ月',
    DAILY: '日',
    COUNT: '件'
} as const;

export type SamePropertyConditionUnit = typeof SamePropertyConditionUnit[keyof typeof SamePropertyConditionUnit];


/**
 * 買主会員検索ステータス
 * @export
 * @enum {string}
 */

export const SearchMemberStatus = {
    REGISTERED: '買主会員',
    CANCEL: '退会'
} as const;

export type SearchMemberStatus = typeof SearchMemberStatus[keyof typeof SearchMemberStatus];


/**
 * テストメールリクエストボディ
 * @export
 * @interface SendTestMailRequest
 */
export interface SendTestMailRequest {
    /**
     * 宛先
     * @type {string}
     * @memberof SendTestMailRequest
     */
    'toMailAddress': string;
    /**
     * 顧客名表示
     * @type {boolean}
     * @memberof SendTestMailRequest
     */
    'isNameDisplayed': boolean;
    /**
     * メールタイトル
     * @type {string}
     * @memberof SendTestMailRequest
     */
    'title': string;
    /**
     * メールテキスト(HTML)
     * @type {string}
     * @memberof SendTestMailRequest
     */
    'mailText': string;
}
/**
 * Setting
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {number}
     * @memberof Setting
     */
    'id'?: number;
    /**
     * 同一物件表示（成約済物件）
     * @type {boolean}
     * @memberof Setting
     */
    'showsSameProperties': boolean;
    /**
     * 
     * @type {SamePropertyConditionUnit}
     * @memberof Setting
     */
    'samePropertyConditionUnit': SamePropertyConditionUnit;
    /**
     * 成約済物件表示条件
     * @type {number}
     * @memberof Setting
     */
    'samePropertyConditionValue': number;
    /**
     * 新着マーク表示
     * @type {boolean}
     * @memberof Setting
     */
    'showsNewMarks': boolean;
    /**
     * 新着マーク表示期間
     * @type {number}
     * @memberof Setting
     */
    'newMarkPeriod': number;
    /**
     * 価格変更マーク表示
     * @type {boolean}
     * @memberof Setting
     */
    'showsPriceChangeMarks': boolean;
    /**
     * 価格変更マーク表示期間
     * @type {number}
     * @memberof Setting
     */
    'priceChangeMarkPeriod': number;
    /**
     * 
     * @type {NextUpdatePeriodUnit}
     * @memberof Setting
     */
    'nextUpdatePeriodUnit': NextUpdatePeriodUnit;
    /**
     * 次回更新予定期間
     * @type {number}
     * @memberof Setting
     */
    'nextUpdatePeriodValue': number;
    /**
     * 対象ステータス　最低年齢
     * @type {number}
     * @memberof Setting
     */
    'minAge': number;
    /**
     * 対象ステータス　最高年齢
     * @type {number}
     * @memberof Setting
     */
    'maxAge': number;
    /**
     * 対象ステータス　最低年収
     * @type {number}
     * @memberof Setting
     */
    'minAnnualIncome': number;
    /**
     * 借入金額の上限
     * @type {number}
     * @memberof Setting
     */
    'maxLoanAmount': number;
    /**
     * 実効金利
     * @type {number}
     * @memberof Setting
     */
    'interestRate': number;
    /**
     * 最長借入年数
     * @type {number}
     * @memberof Setting
     */
    'maxLoanPeriod': number;
    /**
     * 完済時最大年齢
     * @type {number}
     * @memberof Setting
     */
    'loanPayoffMaxAge': number;
}
/**
 * 設定・管理権限
 * @export
 * @enum {string}
 */

export const SettingAdminAuthority = {
    NO_AUTHORITY: '権限なし',
    VIEW_AND_EDIT: '閲覧・編集可'
} as const;

export type SettingAdminAuthority = typeof SettingAdminAuthority[keyof typeof SettingAdminAuthority];


/**
 * Station
 * @export
 * @interface Station
 */
export interface Station {
    /**
     * 
     * @type {number}
     * @memberof Station
     */
    'id'?: number;
    /**
     * 路線ID
     * @type {number}
     * @memberof Station
     */
    'lineId': number;
    /**
     * 路線名
     * @type {string}
     * @memberof Station
     */
    'lineName'?: string;
    /**
     * 駅ID
     * @type {number}
     * @memberof Station
     */
    'stationId': number;
    /**
     * 駅名
     * @type {string}
     * @memberof Station
     */
    'stationName'?: string;
}
/**
 * 建物構造
 * @export
 * @enum {string}
 */

export const Structure = {
    WOODEN: '木造',
    BLOCK: 'ブロック',
    STEEL: '鉄骨造',
    RC: '鉄筋コンクリート',
    SRC: '鉄骨鉄筋コンクリート',
    PC: 'プレキャストコンクリート',
    HPC: '鉄骨プレキャストコンクリート',
    LIGHT_GAUGE_STEEL: '軽量鉄骨',
    OTHER: 'その他'
} as const;

export type Structure = typeof Structure[keyof typeof Structure];


/**
 * 入居状況
 * @export
 * @enum {string}
 */

export const TenancyStatus = {
    LEASE: '賃貸中',
    VACANCY: '空室',
    LIVE: '居住中'
} as const;

export type TenancyStatus = typeof TenancyStatus[keyof typeof TenancyStatus];


/**
 * 取引態様
 * @export
 * @enum {string}
 */

export const TransactionType = {
    SELLER: '売主',
    AGENT: '代理',
    EXCLUSIVE_RIGHT_TO_SELL_LISTING_AGREEMENT: '専属',
    EXCLUSIVE_AGENCY_LISTING_AGREEMENT: '専任',
    OPEN_LISTING_AGREEMENT: '一般'
} as const;

export type TransactionType = typeof TransactionType[keyof typeof TransactionType];


/**
 * 引渡時期
 * @export
 * @enum {string}
 */

export const TransferStatus = {
    IMMEDIATE_1: '即時',
    IMMEDIATE_2: '即入居可',
    CONSULTATION: '相談',
    SPECIFIED_DATE: '期日指定',
    SCHEDULED_DATE: '予定',
    UNFIXED: '未定'
} as const;

export type TransferStatus = typeof TransferStatus[keyof typeof TransferStatus];


/**
 * 非公開物件情報
 * @export
 * @interface UnPublishedProperty
 */
export interface UnPublishedProperty extends Property {
    /**
     * ID
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'id'?: number;
    /**
     * 表示用ID
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'displayId'?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'prefectureName'?: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'cityName'?: string;
    /**
     * 町丁目
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'townName'?: string;
    /**
     * 路線名
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'lineName'?: string;
    /**
     * 駅名
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'stationName'?: string;
    /**
     * 駅徒歩分
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'walkMinute'?: number;
    /**
     * マンション名
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'mansionName'?: string;
    /**
     * 建物階数
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'totalStory'?: number;
    /**
     * 築年月
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'builtDate'?: string;
    /**
     * 
     * @type {Structure}
     * @memberof UnPublishedProperty
     */
    'structure'?: Structure;
    /**
     * 総戸数
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'totalUnit'?: number;
    /**
     * 土地権利
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'landRight'?: string;
    /**
     * マンションID
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'mansionId'?: string;
    /**
     * 部屋番号
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'roomNumber'?: string;
    /**
     * 間取り
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'floorPlan'?: string;
    /**
     * 所在階
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'story'?: number;
    /**
     * 専有面積
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'unitSize'?: number;
    /**
     * バルコニー面積
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'balconySize'?: number;
    /**
     * 
     * @type {TenancyStatus}
     * @memberof UnPublishedProperty
     */
    'tenancyStatus'?: TenancyStatus;
    /**
     * 
     * @type {RentManagementStatus}
     * @memberof UnPublishedProperty
     */
    'rentManagementStatus'?: RentManagementStatus;
    /**
     * 
     * @type {RentType}
     * @memberof UnPublishedProperty
     */
    'rentType'?: RentType;
    /**
     * 賃料
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'rentPrice'?: number;
    /**
     * 賃貸管理手数料
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'rentManagementFee'?: number;
    /**
     * 賃貸管理会社
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'rentManagementCompany'?: string;
    /**
     * 管理費
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'maintenanceFee'?: number;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'repairingFee'?: number;
    /**
     * 建物管理会社
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'buildingManagementCompany'?: string;
    /**
     * 
     * @type {MaintenanceType}
     * @memberof UnPublishedProperty
     */
    'maintenanceType'?: MaintenanceType;
    /**
     * 販売価格
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'salesPrice'?: number;
    /**
     * 
     * @type {TransactionType}
     * @memberof UnPublishedProperty
     */
    'transactionType'?: TransactionType;
    /**
     * 担当者ID
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'agentId'?: string;
    /**
     * 担当者名
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'agentName'?: string;
    /**
     * キャッチコピー
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'catchCopy'?: string;
    /**
     * コメント
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'description'?: string;
    /**
     * 
     * @type {PropertyStatus}
     * @memberof UnPublishedProperty
     */
    'propertyStatus'?: PropertyStatus;
    /**
     * 新着フラグ
     * @type {boolean}
     * @memberof UnPublishedProperty
     */
    'isNew'?: boolean;
    /**
     * 価格変更フラグ
     * @type {boolean}
     * @memberof UnPublishedProperty
     */
    'hasPriceChanged'?: boolean;
    /**
     * 成約日
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'contractDate'?: string;
    /**
     * 成約価格
     * @type {number}
     * @memberof UnPublishedProperty
     */
    'contractPrice'?: number;
    /**
     * 備考
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'note'?: string;
    /**
     * 
     * @type {Office}
     * @memberof UnPublishedProperty
     */
    'office'?: Office;
    /**
     * 掲載画像
     * @type {Array<PropertyImage>}
     * @memberof UnPublishedProperty
     */
    'propertyImages'?: Array<PropertyImage>;
    /**
     * 
     * @type {RevenueSimulation}
     * @memberof UnPublishedProperty
     */
    'simulation'?: RevenueSimulation;
    /**
     * 初回掲載日
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'firstPublishedAt'?: string;
    /**
     * 価格変更日
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'priceUpdatedAt'?: string;
    /**
     * 次回情報更新日
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'nextUpdateDate'?: string;
    /**
     * 作成日
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'createdAt'?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof UnPublishedProperty
     */
    'updatedAt'?: string;
}
/**
 * Doorman 住戸
 * @export
 * @interface Unit
 */
export interface Unit extends DoormanProperty {
    /**
     * 
     * @type {UnitMansion}
     * @memberof Unit
     */
    'mansion': UnitMansion;
    /**
     * 部屋番号
     * @type {string}
     * @memberof Unit
     */
    'number'?: string;
    /**
     * 所在階
     * @type {number}
     * @memberof Unit
     */
    'story': number;
    /**
     * 専有面積
     * @type {number}
     * @memberof Unit
     */
    'size': number;
    /**
     * 間取り
     * @type {string}
     * @memberof Unit
     */
    'floorPlan': string;
    /**
     * 
     * @type {BalconyDirection}
     * @memberof Unit
     */
    'balconyDirection'?: BalconyDirection;
    /**
     * バルコニー面積
     * @type {number}
     * @memberof Unit
     */
    'balconySize'?: number;
    /**
     * 角部屋
     * @type {boolean}
     * @memberof Unit
     */
    'isCorner'?: boolean;
    /**
     * 管理費
     * @type {number}
     * @memberof Unit
     */
    'maintenanceFee'?: number;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof Unit
     */
    'repairingFee'?: number;
    /**
     * 
     * @type {TransactionType}
     * @memberof Unit
     */
    'transactionType'?: TransactionType;
    /**
     * 
     * @type {TransferStatus}
     * @memberof Unit
     */
    'transferStatus'?: TransferStatus;
    /**
     * 
     * @type {MeasurementMethod}
     * @memberof Unit
     */
    'measurementMethod'?: MeasurementMethod;
    /**
     * 
     * @type {DoormanCurrentStatus}
     * @memberof Unit
     */
    'currentStatus'?: DoormanCurrentStatus;
    /**
     * オーナーチェンジ
     * @type {boolean}
     * @memberof Unit
     */
    'ownerChange'?: boolean;
    /**
     * 新築時価格
     * @type {number}
     * @memberof Unit
     */
    'salePrice'?: number;
}
/**
 * Doorman 住戸 データ
 * @export
 * @interface UnitAttributes
 */
export interface UnitAttributes {
    /**
     * 
     * @type {UnitMansion}
     * @memberof UnitAttributes
     */
    'mansion'?: UnitMansion;
    /**
     * 部屋番号
     * @type {string}
     * @memberof UnitAttributes
     */
    'number'?: string;
    /**
     * 所在階
     * @type {number}
     * @memberof UnitAttributes
     */
    'story'?: number;
    /**
     * 専有面積
     * @type {number}
     * @memberof UnitAttributes
     */
    'size'?: number;
    /**
     * 間取り
     * @type {string}
     * @memberof UnitAttributes
     */
    'floorPlan'?: string;
    /**
     * 
     * @type {BalconyDirection}
     * @memberof UnitAttributes
     */
    'balconyDirection'?: BalconyDirection;
    /**
     * バルコニー面積
     * @type {number}
     * @memberof UnitAttributes
     */
    'balconySize'?: number;
    /**
     * 角部屋
     * @type {boolean}
     * @memberof UnitAttributes
     */
    'isCorner'?: boolean;
    /**
     * 管理費
     * @type {number}
     * @memberof UnitAttributes
     */
    'maintenanceFee'?: number;
    /**
     * 修繕積立金
     * @type {number}
     * @memberof UnitAttributes
     */
    'repairingFee'?: number;
    /**
     * 
     * @type {TransactionType}
     * @memberof UnitAttributes
     */
    'transactionType'?: TransactionType;
    /**
     * 
     * @type {TransferStatus}
     * @memberof UnitAttributes
     */
    'transferStatus'?: TransferStatus;
    /**
     * 
     * @type {MeasurementMethod}
     * @memberof UnitAttributes
     */
    'measurementMethod'?: MeasurementMethod;
    /**
     * 
     * @type {DoormanCurrentStatus}
     * @memberof UnitAttributes
     */
    'currentStatus'?: DoormanCurrentStatus;
    /**
     * オーナーチェンジ
     * @type {boolean}
     * @memberof UnitAttributes
     */
    'ownerChange'?: boolean;
    /**
     * 新築時価格
     * @type {number}
     * @memberof UnitAttributes
     */
    'salePrice'?: number;
}
/**
 * 住戸のマンション。   id の指定が無い場合は address, traffic, name, builtDate が必須項目となります。 
 * @export
 * @interface UnitMansion
 */
export interface UnitMansion {
    /**
     * 物件ID
     * @type {number}
     * @memberof UnitMansion
     */
    'id'?: number;
    /**
     * 
     * @type {DoormanAddress}
     * @memberof UnitMansion
     */
    'address'?: DoormanAddress;
    /**
     * 
     * @type {DoormanTraffic}
     * @memberof UnitMansion
     */
    'traffic'?: DoormanTraffic;
    /**
     * 物件名
     * @type {string}
     * @memberof UnitMansion
     */
    'name'?: string;
    /**
     * 築年月
     * @type {string}
     * @memberof UnitMansion
     */
    'builtDate'?: string;
    /**
     * 建物構造
     * @type {string}
     * @memberof UnitMansion
     */
    'structure'?: string;
    /**
     * 総戸数
     * @type {number}
     * @memberof UnitMansion
     */
    'totalUnitCount'?: number;
    /**
     * 棟総戸数
     * @type {number}
     * @memberof UnitMansion
     */
    'buildingTotalUnitCount'?: number;
    /**
     * 地上階層
     * @type {number}
     * @memberof UnitMansion
     */
    'totalStory'?: number;
    /**
     * 用途地域
     * @type {string}
     * @memberof UnitMansion
     */
    'useDistrict'?: string;
    /**
     * 土地権利
     * @type {string}
     * @memberof UnitMansion
     */
    'landRights'?: string;
    /**
     * 施主
     * @type {string}
     * @memberof UnitMansion
     */
    'owner'?: string;
    /**
     * 分譲会社
     * @type {string}
     * @memberof UnitMansion
     */
    'developmentCompany'?: string;
    /**
     * 施工会社
     * @type {string}
     * @memberof UnitMansion
     */
    'builtCompany'?: string;
    /**
     * 管理会社
     * @type {string}
     * @memberof UnitMansion
     */
    'maintenanceCompany'?: string;
    /**
     * 管理人状況
     * @type {string}
     * @memberof UnitMansion
     */
    'maintenanceType'?: string;
    /**
     * 管理形態
     * @type {string}
     * @memberof UnitMansion
     */
    'maintenanceStyle'?: string;
}
/**
 * 物件ID
 * @export
 * @interface UnitMansionPropertyId
 */
export interface UnitMansionPropertyId {
    /**
     * 物件ID
     * @type {number}
     * @memberof UnitMansionPropertyId
     */
    'id'?: number;
}
/**
 * 問合せ更新リクエストボディ
 * @export
 * @interface UpdateInquiryAdminRequest
 */
export interface UpdateInquiryAdminRequest {
    /**
     * 
     * @type {InquiryType}
     * @memberof UpdateInquiryAdminRequest
     */
    'type'?: InquiryType;
    /**
     * ご用件
     * @type {string}
     * @memberof UpdateInquiryAdminRequest
     */
    'request': string;
    /**
     * 問合せ内容
     * @type {string}
     * @memberof UpdateInquiryAdminRequest
     */
    'content'?: string;
    /**
     * 担当者ID
     * @type {string}
     * @memberof UpdateInquiryAdminRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {InquiryStatus}
     * @memberof UpdateInquiryAdminRequest
     */
    'status'?: InquiryStatus;
    /**
     * 備考
     * @type {string}
     * @memberof UpdateInquiryAdminRequest
     */
    'note'?: string;
}
/**
 * 問合せ更新リクエストボディ
 * @export
 * @interface UpdateInquiryRequest
 */
export interface UpdateInquiryRequest {
    /**
     * ご用件
     * @type {string}
     * @memberof UpdateInquiryRequest
     */
    'request': string;
    /**
     * 問合せ内容
     * @type {string}
     * @memberof UpdateInquiryRequest
     */
    'content'?: string;
}
/**
 * LoggedInUserのUpdate
 * @export
 * @interface UpdateLoggedInAgentRequest
 */
export interface UpdateLoggedInAgentRequest {
    /**
     * 氏名
     * @type {string}
     * @memberof UpdateLoggedInAgentRequest
     */
    'name': string;
    /**
     * 事業所ID
     * @type {number}
     * @memberof UpdateLoggedInAgentRequest
     */
    'officeId': number;
    /**
     * 会社名
     * @type {string}
     * @memberof UpdateLoggedInAgentRequest
     */
    'companyName'?: string;
    /**
     * 事業所名
     * @type {string}
     * @memberof UpdateLoggedInAgentRequest
     */
    'officeName'?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof UpdateLoggedInAgentRequest
     */
    'mail': string;
    /**
     * 有効・無効
     * @type {boolean}
     * @memberof UpdateLoggedInAgentRequest
     */
    'enabled': boolean;
    /**
     * cognitoでメール認証済みかどうか
     * @type {boolean}
     * @memberof UpdateLoggedInAgentRequest
     */
    'mailVerified': boolean;
}
/**
 * 物件ステータス
 * @export
 * @enum {string}
 */

export const VisibilityStatus = {
    PUBLISH: '公開',
    UNPUBLISH: '非公開'
} as const;

export type VisibilityStatus = typeof VisibilityStatus[keyof typeof VisibilityStatus];



/**
 * AdminAgentApi - axios parameter creator
 * @export
 */
export const AdminAgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Agent} agent 担当者登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent: async (agent: Agent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agent' is not null or undefined
            assertParamExists('createAgent', 'agent', agent)
            const localVarPath = `/core/v1/admin/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('deleteAgent', 'agentId', agentId)
            const localVarPath = `/core/v1/admin/agents/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAgent', 'agentId', agentId)
            const localVarPath = `/core/v1/admin/agents/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PropertyAuthority} [propertyAuthority] 物件情報権限
         * @param {InquiryAuthority} [inquiryAuthority] 問合せ情報権限
         * @param {boolean} [enabledOnly] 有効ユーザーのみ/全ユーザー 切替フラグ
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents: async (propertyAuthority?: PropertyAuthority, inquiryAuthority?: InquiryAuthority, enabledOnly?: boolean, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (propertyAuthority !== undefined) {
                localVarQueryParameter['propertyAuthority'] = propertyAuthority;
            }

            if (inquiryAuthority !== undefined) {
                localVarQueryParameter['inquiryAuthority'] = inquiryAuthority;
            }

            if (enabledOnly !== undefined) {
                localVarQueryParameter['enabledOnly'] = enabledOnly;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInAgent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/agents/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 仮パスワード再発行
         * @param {ResendRequest} resendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendTemporaryPassword: async (resendRequest: ResendRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendRequest' is not null or undefined
            assertParamExists('resendTemporaryPassword', 'resendRequest', resendRequest)
            const localVarPath = `/core/v1/admin/agents/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {Agent} agent 担当者更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent: async (agentId: string, agent: Agent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateAgent', 'agentId', agentId)
            // verify required parameter 'agent' is not null or undefined
            assertParamExists('updateAgent', 'agent', agent)
            const localVarPath = `/core/v1/admin/agents/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLoggedInAgentRequest} updateLoggedInAgentRequest ログイン担当者更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoggedInAgent: async (updateLoggedInAgentRequest: UpdateLoggedInAgentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLoggedInAgentRequest' is not null or undefined
            assertParamExists('updateLoggedInAgent', 'updateLoggedInAgentRequest', updateLoggedInAgentRequest)
            const localVarPath = `/core/v1/admin/agents/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoggedInAgentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAgentApi - functional programming interface
 * @export
 */
export const AdminAgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAgentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Agent} agent 担当者登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgent(agent: Agent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgent(agent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PropertyAuthority} [propertyAuthority] 物件情報権限
         * @param {InquiryAuthority} [inquiryAuthority] 問合せ情報権限
         * @param {boolean} [enabledOnly] 有効ユーザーのみ/全ユーザー 切替フラグ
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgents(propertyAuthority?: PropertyAuthority, inquiryAuthority?: InquiryAuthority, enabledOnly?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgents(propertyAuthority, inquiryAuthority, enabledOnly, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoggedInAgent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoggedInAgent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 仮パスワード再発行
         * @param {ResendRequest} resendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendTemporaryPassword(resendRequest: ResendRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendTemporaryPassword(resendRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {Agent} agent 担当者更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent(agentId: string, agent: Agent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgent(agentId, agent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateLoggedInAgentRequest} updateLoggedInAgentRequest ログイン担当者更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoggedInAgent(updateLoggedInAgentRequest: UpdateLoggedInAgentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoggedInAgent(updateLoggedInAgentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAgentApi - factory interface
 * @export
 */
export const AdminAgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAgentApiFp(configuration)
    return {
        /**
         * 
         * @param {Agent} agent 担当者登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent(agent: Agent, options?: any): AxiosPromise<Agent> {
            return localVarFp.createAgent(agent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent(agentId: string, options?: any): AxiosPromise<Agent> {
            return localVarFp.deleteAgent(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(agentId: string, options?: any): AxiosPromise<Agent> {
            return localVarFp.getAgent(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PropertyAuthority} [propertyAuthority] 物件情報権限
         * @param {InquiryAuthority} [inquiryAuthority] 問合せ情報権限
         * @param {boolean} [enabledOnly] 有効ユーザーのみ/全ユーザー 切替フラグ
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents(propertyAuthority?: PropertyAuthority, inquiryAuthority?: InquiryAuthority, enabledOnly?: boolean, offset?: number, limit?: number, options?: any): AxiosPromise<Agents> {
            return localVarFp.getAgents(propertyAuthority, inquiryAuthority, enabledOnly, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInAgent(options?: any): AxiosPromise<Agent> {
            return localVarFp.getLoggedInAgent(options).then((request) => request(axios, basePath));
        },
        /**
         * 仮パスワード再発行
         * @param {ResendRequest} resendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendTemporaryPassword(resendRequest: ResendRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resendTemporaryPassword(resendRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} agentId 担当者ID
         * @param {Agent} agent 担当者更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent(agentId: string, agent: Agent, options?: any): AxiosPromise<Agent> {
            return localVarFp.updateAgent(agentId, agent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLoggedInAgentRequest} updateLoggedInAgentRequest ログイン担当者更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoggedInAgent(updateLoggedInAgentRequest: UpdateLoggedInAgentRequest, options?: any): AxiosPromise<Agent> {
            return localVarFp.updateLoggedInAgent(updateLoggedInAgentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAgentApi - object-oriented interface
 * @export
 * @class AdminAgentApi
 * @extends {BaseAPI}
 */
export class AdminAgentApi extends BaseAPI {
    /**
     * 
     * @param {Agent} agent 担当者登録リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public createAgent(agent: Agent, options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).createAgent(agent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agentId 担当者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public deleteAgent(agentId: string, options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).deleteAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agentId 担当者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public getAgent(agentId: string, options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).getAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PropertyAuthority} [propertyAuthority] 物件情報権限
     * @param {InquiryAuthority} [inquiryAuthority] 問合せ情報権限
     * @param {boolean} [enabledOnly] 有効ユーザーのみ/全ユーザー 切替フラグ
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public getAgents(propertyAuthority?: PropertyAuthority, inquiryAuthority?: InquiryAuthority, enabledOnly?: boolean, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).getAgents(propertyAuthority, inquiryAuthority, enabledOnly, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public getLoggedInAgent(options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).getLoggedInAgent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 仮パスワード再発行
     * @param {ResendRequest} resendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public resendTemporaryPassword(resendRequest: ResendRequest, options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).resendTemporaryPassword(resendRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} agentId 担当者ID
     * @param {Agent} agent 担当者更新リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public updateAgent(agentId: string, agent: Agent, options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).updateAgent(agentId, agent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLoggedInAgentRequest} updateLoggedInAgentRequest ログイン担当者更新リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAgentApi
     */
    public updateLoggedInAgent(updateLoggedInAgentRequest: UpdateLoggedInAgentRequest, options?: AxiosRequestConfig) {
        return AdminAgentApiFp(this.configuration).updateLoggedInAgent(updateLoggedInAgentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminCitiesApi - axios parameter creator
 * @export
 */
export const AdminCitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GroupCode} groupCode グループコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByGroupCode: async (groupCode: GroupCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupCode' is not null or undefined
            assertParamExists('getCitiesByGroupCode', 'groupCode', groupCode)
            const localVarPath = `/core/v1/admin/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupCode !== undefined) {
                localVarQueryParameter['groupCode'] = groupCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCitiesApi - functional programming interface
 * @export
 */
export const AdminCitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GroupCode} groupCode グループコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesByGroupCode(groupCode: GroupCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesByGroupCode(groupCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminCitiesApi - factory interface
 * @export
 */
export const AdminCitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {GroupCode} groupCode グループコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByGroupCode(groupCode: GroupCode, options?: any): AxiosPromise<Cities> {
            return localVarFp.getCitiesByGroupCode(groupCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCitiesApi - object-oriented interface
 * @export
 * @class AdminCitiesApi
 * @extends {BaseAPI}
 */
export class AdminCitiesApi extends BaseAPI {
    /**
     * 
     * @param {GroupCode} groupCode グループコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCitiesApi
     */
    public getCitiesByGroupCode(groupCode: GroupCode, options?: AxiosRequestConfig) {
        return AdminCitiesApiFp(this.configuration).getCitiesByGroupCode(groupCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDownloadApi - axios parameter creator
 * @export
 */
export const AdminDownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DownloadInquiriesRequest} downloadInquiriesRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInquiries: async (downloadInquiriesRequest: DownloadInquiriesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadInquiriesRequest' is not null or undefined
            assertParamExists('downloadInquiries', 'downloadInquiriesRequest', downloadInquiriesRequest)
            const localVarPath = `/core/v1/admin/download/inquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadInquiriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [memberId] 会員ID
         * @param {string} [name] 名前
         * @param {SearchMemberStatus} [status] 会員ステータス
         * @param {boolean} [mailDelivery] メール配信
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [from] 会員登録日（開始）
         * @param {string} [to] 会員登録日（終了）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMembers: async (memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/download/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (mailDelivery !== undefined) {
                localVarQueryParameter['mailDelivery'] = mailDelivery;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] 物件名
         * @param {string} [roomNumber] 部屋番号
         * @param {Array<PropertyStatus>} [propertyStatus] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProperties: async (propertyId?: number, mansionName?: string, roomNumber?: string, propertyStatus?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/download/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (mansionName !== undefined) {
                localVarQueryParameter['mansionName'] = mansionName;
            }

            if (roomNumber !== undefined) {
                localVarQueryParameter['roomNumber'] = roomNumber;
            }

            if (propertyStatus) {
                localVarQueryParameter['propertyStatus'] = propertyStatus;
            }

            if (visibilityStatus !== undefined) {
                localVarQueryParameter['visibilityStatus'] = visibilityStatus;
            }

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDownloadApi - functional programming interface
 * @export
 */
export const AdminDownloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDownloadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DownloadInquiriesRequest} downloadInquiriesRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInquiries(downloadInquiriesRequest: DownloadInquiriesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInquiries(downloadInquiriesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [memberId] 会員ID
         * @param {string} [name] 名前
         * @param {SearchMemberStatus} [status] 会員ステータス
         * @param {boolean} [mailDelivery] メール配信
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [from] 会員登録日（開始）
         * @param {string} [to] 会員登録日（終了）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMembers(memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMembers(memberId, name, status, mailDelivery, partnerId, limit, offset, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] 物件名
         * @param {string} [roomNumber] 部屋番号
         * @param {Array<PropertyStatus>} [propertyStatus] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadProperties(propertyId?: number, mansionName?: string, roomNumber?: string, propertyStatus?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadProperties(propertyId, mansionName, roomNumber, propertyStatus, visibilityStatus, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDownloadApi - factory interface
 * @export
 */
export const AdminDownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDownloadApiFp(configuration)
    return {
        /**
         * 
         * @param {DownloadInquiriesRequest} downloadInquiriesRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInquiries(downloadInquiriesRequest: DownloadInquiriesRequest, options?: any): AxiosPromise<string> {
            return localVarFp.downloadInquiries(downloadInquiriesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [memberId] 会員ID
         * @param {string} [name] 名前
         * @param {SearchMemberStatus} [status] 会員ステータス
         * @param {boolean} [mailDelivery] メール配信
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [from] 会員登録日（開始）
         * @param {string} [to] 会員登録日（終了）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMembers(memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadMembers(memberId, name, status, mailDelivery, partnerId, limit, offset, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] 物件名
         * @param {string} [roomNumber] 部屋番号
         * @param {Array<PropertyStatus>} [propertyStatus] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProperties(propertyId?: number, mansionName?: string, roomNumber?: string, propertyStatus?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadProperties(propertyId, mansionName, roomNumber, propertyStatus, visibilityStatus, agentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDownloadApi - object-oriented interface
 * @export
 * @class AdminDownloadApi
 * @extends {BaseAPI}
 */
export class AdminDownloadApi extends BaseAPI {
    /**
     * 
     * @param {DownloadInquiriesRequest} downloadInquiriesRequest body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDownloadApi
     */
    public downloadInquiries(downloadInquiriesRequest: DownloadInquiriesRequest, options?: AxiosRequestConfig) {
        return AdminDownloadApiFp(this.configuration).downloadInquiries(downloadInquiriesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [memberId] 会員ID
     * @param {string} [name] 名前
     * @param {SearchMemberStatus} [status] 会員ステータス
     * @param {boolean} [mailDelivery] メール配信
     * @param {string} [partnerId] パートナーID
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {string} [from] 会員登録日（開始）
     * @param {string} [to] 会員登録日（終了）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDownloadApi
     */
    public downloadMembers(memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return AdminDownloadApiFp(this.configuration).downloadMembers(memberId, name, status, mailDelivery, partnerId, limit, offset, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [propertyId] 物件ID
     * @param {string} [mansionName] 物件名
     * @param {string} [roomNumber] 部屋番号
     * @param {Array<PropertyStatus>} [propertyStatus] 物件ステータス
     * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
     * @param {string} [agentId] 担当者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDownloadApi
     */
    public downloadProperties(propertyId?: number, mansionName?: string, roomNumber?: string, propertyStatus?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, options?: AxiosRequestConfig) {
        return AdminDownloadApiFp(this.configuration).downloadProperties(propertyId, mansionName, roomNumber, propertyStatus, visibilityStatus, agentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminMemberApi - axios parameter creator
 * @export
 */
export const AdminMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 会員情報取得
         * @param {number} memberId 会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerMember: async (memberId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getBuyerMember', 'memberId', memberId)
            const localVarPath = `/core/v1/admin/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員検索
         * @param {number} [memberId] 会員ID
         * @param {string} [name] 氏名. 姓, 名, 姓(ふりがな), 名(ふりがな)のいずれかの部分一致. 
         * @param {SearchMemberStatus} [status] 会員ステータス
         * @param {boolean} [mailDelivery] メール配信
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [from] 会員登録日（開始）
         * @param {string} [to] 会員登録日（終了）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerMembers: async (memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (mailDelivery !== undefined) {
                localVarQueryParameter['mailDelivery'] = mailDelivery;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報更新
         * @param {number} memberId 会員ID
         * @param {Member} member 更新会員情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuyerMember: async (memberId: number, member: Member, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateBuyerMember', 'memberId', memberId)
            // verify required parameter 'member' is not null or undefined
            assertParamExists('updateBuyerMember', 'member', member)
            const localVarPath = `/core/v1/admin/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(member, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMemberApi - functional programming interface
 * @export
 */
export const AdminMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 会員情報取得
         * @param {number} memberId 会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyerMember(memberId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyerMember(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員検索
         * @param {number} [memberId] 会員ID
         * @param {string} [name] 氏名. 姓, 名, 姓(ふりがな), 名(ふりがな)のいずれかの部分一致. 
         * @param {SearchMemberStatus} [status] 会員ステータス
         * @param {boolean} [mailDelivery] メール配信
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [from] 会員登録日（開始）
         * @param {string} [to] 会員登録日（終了）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyerMembers(memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Members>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyerMembers(memberId, name, status, mailDelivery, partnerId, limit, offset, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報更新
         * @param {number} memberId 会員ID
         * @param {Member} member 更新会員情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBuyerMember(memberId: number, member: Member, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBuyerMember(memberId, member, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMemberApi - factory interface
 * @export
 */
export const AdminMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMemberApiFp(configuration)
    return {
        /**
         * 会員情報取得
         * @param {number} memberId 会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerMember(memberId: number, options?: any): AxiosPromise<Member> {
            return localVarFp.getBuyerMember(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員検索
         * @param {number} [memberId] 会員ID
         * @param {string} [name] 氏名. 姓, 名, 姓(ふりがな), 名(ふりがな)のいずれかの部分一致. 
         * @param {SearchMemberStatus} [status] 会員ステータス
         * @param {boolean} [mailDelivery] メール配信
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [from] 会員登録日（開始）
         * @param {string} [to] 会員登録日（終了）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerMembers(memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options?: any): AxiosPromise<Members> {
            return localVarFp.getBuyerMembers(memberId, name, status, mailDelivery, partnerId, limit, offset, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報更新
         * @param {number} memberId 会員ID
         * @param {Member} member 更新会員情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuyerMember(memberId: number, member: Member, options?: any): AxiosPromise<Member> {
            return localVarFp.updateBuyerMember(memberId, member, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminMemberApi - object-oriented interface
 * @export
 * @class AdminMemberApi
 * @extends {BaseAPI}
 */
export class AdminMemberApi extends BaseAPI {
    /**
     * 会員情報取得
     * @param {number} memberId 会員ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public getBuyerMember(memberId: number, options?: AxiosRequestConfig) {
        return AdminMemberApiFp(this.configuration).getBuyerMember(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員検索
     * @param {number} [memberId] 会員ID
     * @param {string} [name] 氏名. 姓, 名, 姓(ふりがな), 名(ふりがな)のいずれかの部分一致. 
     * @param {SearchMemberStatus} [status] 会員ステータス
     * @param {boolean} [mailDelivery] メール配信
     * @param {string} [partnerId] パートナーID
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {string} [from] 会員登録日（開始）
     * @param {string} [to] 会員登録日（終了）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public getBuyerMembers(memberId?: number, name?: string, status?: SearchMemberStatus, mailDelivery?: boolean, partnerId?: string, limit?: number, offset?: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return AdminMemberApiFp(this.configuration).getBuyerMembers(memberId, name, status, mailDelivery, partnerId, limit, offset, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報更新
     * @param {number} memberId 会員ID
     * @param {Member} member 更新会員情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public updateBuyerMember(memberId: number, member: Member, options?: AxiosRequestConfig) {
        return AdminMemberApiFp(this.configuration).updateBuyerMember(memberId, member, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrganizationApi - axios parameter creator
 * @export
 */
export const AdminOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrganizationApi - functional programming interface
 * @export
 */
export const AdminOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrganizationApi - factory interface
 * @export
 */
export const AdminOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrganizationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(options?: any): AxiosPromise<Organization> {
            return localVarFp.getOrganization(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrganizationApi - object-oriented interface
 * @export
 * @class AdminOrganizationApi
 * @extends {BaseAPI}
 */
export class AdminOrganizationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationApi
     */
    public getOrganization(options?: AxiosRequestConfig) {
        return AdminOrganizationApiFp(this.configuration).getOrganization(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPropertiesApi - axios parameter creator
 * @export
 */
export const AdminPropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Property} [property] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty: async (property?: Property, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(property, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] マンション名
         * @param {string} [roomNumber] 部屋番号
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties: async (propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (propertyStatuses) {
                localVarQueryParameter['propertyStatuses'] = propertyStatuses;
            }

            if (visibilityStatus !== undefined) {
                localVarQueryParameter['visibilityStatus'] = visibilityStatus;
            }

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (mansionName !== undefined) {
                localVarQueryParameter['mansionName'] = mansionName;
            }

            if (roomNumber !== undefined) {
                localVarQueryParameter['roomNumber'] = roomNumber;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getProperty', 'propertyId', propertyId)
            const localVarPath = `/core/v1/admin/properties/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索条件と同じパラメータを取る。 検索結果で表示されているものだけではなく対象になる全てのデータの 「次回更新予定日(next_update_date)」を更新する。更新には Setting テーブルの  next_update_period_unit と next_update_period_value を使用する。 
         * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] マンション名
         * @param {string} [roomNumber] 部屋番号
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertiesNextUpdateDateBySettingValue: async (propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/properties/nextUpdateDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (propertyStatuses) {
                localVarQueryParameter['propertyStatuses'] = propertyStatuses;
            }

            if (visibilityStatus !== undefined) {
                localVarQueryParameter['visibilityStatus'] = visibilityStatus;
            }

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }

            if (propertyId !== undefined) {
                localVarQueryParameter['propertyId'] = propertyId;
            }

            if (mansionName !== undefined) {
                localVarQueryParameter['mansionName'] = mansionName;
            }

            if (roomNumber !== undefined) {
                localVarQueryParameter['roomNumber'] = roomNumber;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {Property} [property] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty: async (propertyId: number, property?: Property, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('updateProperty', 'propertyId', propertyId)
            const localVarPath = `/core/v1/admin/properties/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(property, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPropertiesApi - functional programming interface
 * @export
 */
export const AdminPropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Property} [property] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProperty(property?: Property, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProperty(property, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] マンション名
         * @param {string} [roomNumber] 部屋番号
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperties(propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Properties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperties(propertyStatuses, visibilityStatus, agentId, propertyId, mansionName, roomNumber, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperty(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperty(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索条件と同じパラメータを取る。 検索結果で表示されているものだけではなく対象になる全てのデータの 「次回更新予定日(next_update_date)」を更新する。更新には Setting テーブルの  next_update_period_unit と next_update_period_value を使用する。 
         * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] マンション名
         * @param {string} [roomNumber] 部屋番号
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertiesNextUpdateDateBySettingValue(propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Properties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertiesNextUpdateDateBySettingValue(propertyStatuses, visibilityStatus, agentId, propertyId, mansionName, roomNumber, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {Property} [property] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProperty(propertyId: number, property?: Property, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProperty(propertyId, property, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPropertiesApi - factory interface
 * @export
 */
export const AdminPropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPropertiesApiFp(configuration)
    return {
        /**
         * 
         * @param {Property} [property] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProperty(property?: Property, options?: any): AxiosPromise<Property> {
            return localVarFp.createProperty(property, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] マンション名
         * @param {string} [roomNumber] 部屋番号
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties(propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Properties> {
            return localVarFp.getProperties(propertyStatuses, visibilityStatus, agentId, propertyId, mansionName, roomNumber, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty(propertyId: number, options?: any): AxiosPromise<Property> {
            return localVarFp.getProperty(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索条件と同じパラメータを取る。 検索結果で表示されているものだけではなく対象になる全てのデータの 「次回更新予定日(next_update_date)」を更新する。更新には Setting テーブルの  next_update_period_unit と next_update_period_value を使用する。 
         * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
         * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
         * @param {string} [agentId] 担当者ID
         * @param {number} [propertyId] 物件ID
         * @param {string} [mansionName] マンション名
         * @param {string} [roomNumber] 部屋番号
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertiesNextUpdateDateBySettingValue(propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Properties> {
            return localVarFp.updatePropertiesNextUpdateDateBySettingValue(propertyStatuses, visibilityStatus, agentId, propertyId, mansionName, roomNumber, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {Property} [property] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty(propertyId: number, property?: Property, options?: any): AxiosPromise<Property> {
            return localVarFp.updateProperty(propertyId, property, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPropertiesApi - object-oriented interface
 * @export
 * @class AdminPropertiesApi
 * @extends {BaseAPI}
 */
export class AdminPropertiesApi extends BaseAPI {
    /**
     * 
     * @param {Property} [property] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPropertiesApi
     */
    public createProperty(property?: Property, options?: AxiosRequestConfig) {
        return AdminPropertiesApiFp(this.configuration).createProperty(property, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
     * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
     * @param {string} [agentId] 担当者ID
     * @param {number} [propertyId] 物件ID
     * @param {string} [mansionName] マンション名
     * @param {string} [roomNumber] 部屋番号
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPropertiesApi
     */
    public getProperties(propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return AdminPropertiesApiFp(this.configuration).getProperties(propertyStatuses, visibilityStatus, agentId, propertyId, mansionName, roomNumber, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 物件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPropertiesApi
     */
    public getProperty(propertyId: number, options?: AxiosRequestConfig) {
        return AdminPropertiesApiFp(this.configuration).getProperty(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索条件と同じパラメータを取る。 検索結果で表示されているものだけではなく対象になる全てのデータの 「次回更新予定日(next_update_date)」を更新する。更新には Setting テーブルの  next_update_period_unit と next_update_period_value を使用する。 
     * @param {Array<PropertyStatus>} [propertyStatuses] 物件ステータス
     * @param {VisibilityStatus} [visibilityStatus] 公開ステータス
     * @param {string} [agentId] 担当者ID
     * @param {number} [propertyId] 物件ID
     * @param {string} [mansionName] マンション名
     * @param {string} [roomNumber] 部屋番号
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPropertiesApi
     */
    public updatePropertiesNextUpdateDateBySettingValue(propertyStatuses?: Array<PropertyStatus>, visibilityStatus?: VisibilityStatus, agentId?: string, propertyId?: number, mansionName?: string, roomNumber?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return AdminPropertiesApiFp(this.configuration).updatePropertiesNextUpdateDateBySettingValue(propertyStatuses, visibilityStatus, agentId, propertyId, mansionName, roomNumber, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 物件ID
     * @param {Property} [property] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPropertiesApi
     */
    public updateProperty(propertyId: number, property?: Property, options?: AxiosRequestConfig) {
        return AdminPropertiesApiFp(this.configuration).updateProperty(propertyId, property, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AreamappingsApi - axios parameter creator
 * @export
 */
export const AreamappingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreaMappings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/areaMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreamappingsApi - functional programming interface
 * @export
 */
export const AreamappingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreamappingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAreaMappings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaMappings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAreaMappings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreamappingsApi - factory interface
 * @export
 */
export const AreamappingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreamappingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreaMappings(options?: any): AxiosPromise<AreaMappings> {
            return localVarFp.getAreaMappings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreamappingsApi - object-oriented interface
 * @export
 * @class AreamappingsApi
 * @extends {BaseAPI}
 */
export class AreamappingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreamappingsApi
     */
    public getAreaMappings(options?: AxiosRequestConfig) {
        return AreamappingsApiFp(this.configuration).getAreaMappings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssessmentApi - axios parameter creator
 * @export
 */
export const AssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Assessment} assessment body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessment: async (assessment: Assessment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessment' is not null or undefined
            assertParamExists('createAssessment', 'assessment', assessment)
            const localVarPath = `/core/v1/assessment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} assessmentId AI査定結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessment: async (assessmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentId' is not null or undefined
            assertParamExists('getAssessment', 'assessmentId', assessmentId)
            const localVarPath = `/core/v1/admin/assessment/{assessmentId}`
                .replace(`{${"assessmentId"}}`, encodeURIComponent(String(assessmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentApi - functional programming interface
 * @export
 */
export const AssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Assessment} assessment body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssessment(assessment: Assessment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssessment(assessment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} assessmentId AI査定結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessment(assessmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessment(assessmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssessmentApi - factory interface
 * @export
 */
export const AssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentApiFp(configuration)
    return {
        /**
         * 
         * @param {Assessment} assessment body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessment(assessment: Assessment, options?: any): AxiosPromise<Assessment> {
            return localVarFp.createAssessment(assessment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} assessmentId AI査定結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessment(assessmentId: number, options?: any): AxiosPromise<Assessment> {
            return localVarFp.getAssessment(assessmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentApi - object-oriented interface
 * @export
 * @class AssessmentApi
 * @extends {BaseAPI}
 */
export class AssessmentApi extends BaseAPI {
    /**
     * 
     * @param {Assessment} assessment body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public createAssessment(assessment: Assessment, options?: AxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).createAssessment(assessment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} assessmentId AI査定結果ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public getAssessment(assessmentId: number, options?: AxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).getAssessment(assessmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssessmentCriteriaApi - axios parameter creator
 * @export
 */
export const AssessmentCriteriaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessmentCriteriaArea: async (assessmentCriteriaArea: AssessmentCriteriaArea, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentCriteriaArea' is not null or undefined
            assertParamExists('createAssessmentCriteriaArea', 'assessmentCriteriaArea', assessmentCriteriaArea)
            const localVarPath = `/core/v1/admin/assessmentCriteria/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentCriteriaArea, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 特別駅評価基準を登録します 
         * @summary 特別駅評価基準登録API
         * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessmentCriteriaStation: async (assessmentCriteriaStation: AssessmentCriteriaStation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentCriteriaStation' is not null or undefined
            assertParamExists('createAssessmentCriteriaStation', 'assessmentCriteriaStation', assessmentCriteriaStation)
            const localVarPath = `/core/v1/admin/assessmentCriteria/specialStations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentCriteriaStation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentCriteriaArea: async (areaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('deleteAssessmentCriteriaArea', 'areaId', areaId)
            const localVarPath = `/core/v1/admin/assessmentCriteria/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDを指定して特別駅評価基準を削除します 
         * @summary 特別駅評価基準削除API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentCriteriaStation: async (assessmentCriteriaStationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentCriteriaStationId' is not null or undefined
            assertParamExists('deleteAssessmentCriteriaStation', 'assessmentCriteriaStationId', assessmentCriteriaStationId)
            const localVarPath = `/core/v1/admin/assessmentCriteria/specialStations/{assessmentCriteriaStationId}`
                .replace(`{${"assessmentCriteriaStationId"}}`, encodeURIComponent(String(assessmentCriteriaStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaAreaDetail: async (areaId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('getAssessmentCriteriaAreaDetail', 'areaId', areaId)
            const localVarPath = `/core/v1/admin/assessmentCriteria/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GroupCode} groupCode グループコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaAreas: async (groupCode: GroupCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupCode' is not null or undefined
            assertParamExists('getAssessmentCriteriaAreas', 'groupCode', groupCode)
            const localVarPath = `/core/v1/admin/assessmentCriteria/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupCode !== undefined) {
                localVarQueryParameter['groupCode'] = groupCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDを指定して対象の路線・駅と併せて特別駅評価基準を取得します 
         * @summary 特別駅評価基準取得API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaStation: async (assessmentCriteriaStationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentCriteriaStationId' is not null or undefined
            assertParamExists('getAssessmentCriteriaStation', 'assessmentCriteriaStationId', assessmentCriteriaStationId)
            const localVarPath = `/core/v1/admin/assessmentCriteria/specialStations/{assessmentCriteriaStationId}`
                .replace(`{${"assessmentCriteriaStationId"}}`, encodeURIComponent(String(assessmentCriteriaStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登録されている特別駅評価基準を取得します 
         * @summary 特別駅評価基準検索API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaStations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/assessmentCriteria/specialStations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パラメータ設定のデータを取得します。 
         * @summary パラメータ設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentCriteriaArea: async (areaId: number, assessmentCriteriaArea: AssessmentCriteriaArea, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('updateAssessmentCriteriaArea', 'areaId', areaId)
            // verify required parameter 'assessmentCriteriaArea' is not null or undefined
            assertParamExists('updateAssessmentCriteriaArea', 'assessmentCriteriaArea', assessmentCriteriaArea)
            const localVarPath = `/core/v1/admin/assessmentCriteria/areas/{areaId}`
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentCriteriaArea, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 駅を指定する評価基準のデータを更新します。 対象となる路線、駅名も同時に更新するため、対象駅を更新しない場合でも紐付く情報を指定してください 
         * @summary 特別駅評価基準更新API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentCriteriaStation: async (assessmentCriteriaStationId: number, assessmentCriteriaStation: AssessmentCriteriaStation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assessmentCriteriaStationId' is not null or undefined
            assertParamExists('updateAssessmentCriteriaStation', 'assessmentCriteriaStationId', assessmentCriteriaStationId)
            // verify required parameter 'assessmentCriteriaStation' is not null or undefined
            assertParamExists('updateAssessmentCriteriaStation', 'assessmentCriteriaStation', assessmentCriteriaStation)
            const localVarPath = `/core/v1/admin/assessmentCriteria/specialStations/{assessmentCriteriaStationId}`
                .replace(`{${"assessmentCriteriaStationId"}}`, encodeURIComponent(String(assessmentCriteriaStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assessmentCriteriaStation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パラメータ設定のデータを更新します。 
         * @summary パラメータ設定更新API
         * @param {Setting} setting パラメータ設定保存リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (setting: Setting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setting' is not null or undefined
            assertParamExists('updateSettings', 'setting', setting)
            const localVarPath = `/core/v1/admin/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentCriteriaApi - functional programming interface
 * @export
 */
export const AssessmentCriteriaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentCriteriaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssessmentCriteriaArea(assessmentCriteriaArea: AssessmentCriteriaArea, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssessmentCriteriaArea(assessmentCriteriaArea, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 特別駅評価基準を登録します 
         * @summary 特別駅評価基準登録API
         * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssessmentCriteriaStation(assessmentCriteriaStation: AssessmentCriteriaStation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaStation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssessmentCriteriaStation(assessmentCriteriaStation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssessmentCriteriaArea(areaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssessmentCriteriaArea(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDを指定して特別駅評価基準を削除します 
         * @summary 特別駅評価基準削除API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssessmentCriteriaStation(assessmentCriteriaStationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssessmentCriteriaStation(assessmentCriteriaStationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentCriteriaAreaDetail(areaId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentCriteriaAreaDetail(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GroupCode} groupCode グループコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentCriteriaAreas(groupCode: GroupCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaAreas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentCriteriaAreas(groupCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDを指定して対象の路線・駅と併せて特別駅評価基準を取得します 
         * @summary 特別駅評価基準取得API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentCriteriaStation(assessmentCriteriaStationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaStation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentCriteriaStation(assessmentCriteriaStationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登録されている特別駅評価基準を取得します 
         * @summary 特別駅評価基準検索API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentCriteriaStations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaStations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentCriteriaStations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パラメータ設定のデータを取得します。 
         * @summary パラメータ設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Setting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssessmentCriteriaArea(areaId: number, assessmentCriteriaArea: AssessmentCriteriaArea, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssessmentCriteriaArea(areaId, assessmentCriteriaArea, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 駅を指定する評価基準のデータを更新します。 対象となる路線、駅名も同時に更新するため、対象駅を更新しない場合でも紐付く情報を指定してください 
         * @summary 特別駅評価基準更新API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssessmentCriteriaStation(assessmentCriteriaStationId: number, assessmentCriteriaStation: AssessmentCriteriaStation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentCriteriaStation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssessmentCriteriaStation(assessmentCriteriaStationId, assessmentCriteriaStation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パラメータ設定のデータを更新します。 
         * @summary パラメータ設定更新API
         * @param {Setting} setting パラメータ設定保存リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(setting: Setting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Setting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(setting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssessmentCriteriaApi - factory interface
 * @export
 */
export const AssessmentCriteriaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentCriteriaApiFp(configuration)
    return {
        /**
         * 
         * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessmentCriteriaArea(assessmentCriteriaArea: AssessmentCriteriaArea, options?: any): AxiosPromise<AssessmentCriteriaArea> {
            return localVarFp.createAssessmentCriteriaArea(assessmentCriteriaArea, options).then((request) => request(axios, basePath));
        },
        /**
         * 特別駅評価基準を登録します 
         * @summary 特別駅評価基準登録API
         * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessmentCriteriaStation(assessmentCriteriaStation: AssessmentCriteriaStation, options?: any): AxiosPromise<AssessmentCriteriaStation> {
            return localVarFp.createAssessmentCriteriaStation(assessmentCriteriaStation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentCriteriaArea(areaId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAssessmentCriteriaArea(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * IDを指定して特別駅評価基準を削除します 
         * @summary 特別駅評価基準削除API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentCriteriaStation(assessmentCriteriaStationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAssessmentCriteriaStation(assessmentCriteriaStationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaAreaDetail(areaId: number, options?: any): AxiosPromise<AssessmentCriteriaArea> {
            return localVarFp.getAssessmentCriteriaAreaDetail(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GroupCode} groupCode グループコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaAreas(groupCode: GroupCode, options?: any): AxiosPromise<AssessmentCriteriaAreas> {
            return localVarFp.getAssessmentCriteriaAreas(groupCode, options).then((request) => request(axios, basePath));
        },
        /**
         * IDを指定して対象の路線・駅と併せて特別駅評価基準を取得します 
         * @summary 特別駅評価基準取得API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaStation(assessmentCriteriaStationId: number, options?: any): AxiosPromise<AssessmentCriteriaStation> {
            return localVarFp.getAssessmentCriteriaStation(assessmentCriteriaStationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 登録されている特別駅評価基準を取得します 
         * @summary 特別駅評価基準検索API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentCriteriaStations(options?: any): AxiosPromise<AssessmentCriteriaStations> {
            return localVarFp.getAssessmentCriteriaStations(options).then((request) => request(axios, basePath));
        },
        /**
         * パラメータ設定のデータを取得します。 
         * @summary パラメータ設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<Setting> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} areaId エリア別評価基準ID
         * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentCriteriaArea(areaId: number, assessmentCriteriaArea: AssessmentCriteriaArea, options?: any): AxiosPromise<AssessmentCriteriaArea> {
            return localVarFp.updateAssessmentCriteriaArea(areaId, assessmentCriteriaArea, options).then((request) => request(axios, basePath));
        },
        /**
         * 駅を指定する評価基準のデータを更新します。 対象となる路線、駅名も同時に更新するため、対象駅を更新しない場合でも紐付く情報を指定してください 
         * @summary 特別駅評価基準更新API
         * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
         * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentCriteriaStation(assessmentCriteriaStationId: number, assessmentCriteriaStation: AssessmentCriteriaStation, options?: any): AxiosPromise<AssessmentCriteriaStation> {
            return localVarFp.updateAssessmentCriteriaStation(assessmentCriteriaStationId, assessmentCriteriaStation, options).then((request) => request(axios, basePath));
        },
        /**
         * パラメータ設定のデータを更新します。 
         * @summary パラメータ設定更新API
         * @param {Setting} setting パラメータ設定保存リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(setting: Setting, options?: any): AxiosPromise<Setting> {
            return localVarFp.updateSettings(setting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentCriteriaApi - object-oriented interface
 * @export
 * @class AssessmentCriteriaApi
 * @extends {BaseAPI}
 */
export class AssessmentCriteriaApi extends BaseAPI {
    /**
     * 
     * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public createAssessmentCriteriaArea(assessmentCriteriaArea: AssessmentCriteriaArea, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).createAssessmentCriteriaArea(assessmentCriteriaArea, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 特別駅評価基準を登録します 
     * @summary 特別駅評価基準登録API
     * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準登録リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public createAssessmentCriteriaStation(assessmentCriteriaStation: AssessmentCriteriaStation, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).createAssessmentCriteriaStation(assessmentCriteriaStation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} areaId エリア別評価基準ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public deleteAssessmentCriteriaArea(areaId: number, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).deleteAssessmentCriteriaArea(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDを指定して特別駅評価基準を削除します 
     * @summary 特別駅評価基準削除API
     * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public deleteAssessmentCriteriaStation(assessmentCriteriaStationId: number, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).deleteAssessmentCriteriaStation(assessmentCriteriaStationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} areaId エリア別評価基準ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public getAssessmentCriteriaAreaDetail(areaId: number, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).getAssessmentCriteriaAreaDetail(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GroupCode} groupCode グループコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public getAssessmentCriteriaAreas(groupCode: GroupCode, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).getAssessmentCriteriaAreas(groupCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDを指定して対象の路線・駅と併せて特別駅評価基準を取得します 
     * @summary 特別駅評価基準取得API
     * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public getAssessmentCriteriaStation(assessmentCriteriaStationId: number, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).getAssessmentCriteriaStation(assessmentCriteriaStationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登録されている特別駅評価基準を取得します 
     * @summary 特別駅評価基準検索API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public getAssessmentCriteriaStations(options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).getAssessmentCriteriaStations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パラメータ設定のデータを取得します。 
     * @summary パラメータ設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public getSettings(options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} areaId エリア別評価基準ID
     * @param {AssessmentCriteriaArea} assessmentCriteriaArea body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public updateAssessmentCriteriaArea(areaId: number, assessmentCriteriaArea: AssessmentCriteriaArea, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).updateAssessmentCriteriaArea(areaId, assessmentCriteriaArea, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 駅を指定する評価基準のデータを更新します。 対象となる路線、駅名も同時に更新するため、対象駅を更新しない場合でも紐付く情報を指定してください 
     * @summary 特別駅評価基準更新API
     * @param {number} assessmentCriteriaStationId 特別駅評価基準ID
     * @param {AssessmentCriteriaStation} assessmentCriteriaStation 特別駅評価基準更新リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public updateAssessmentCriteriaStation(assessmentCriteriaStationId: number, assessmentCriteriaStation: AssessmentCriteriaStation, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).updateAssessmentCriteriaStation(assessmentCriteriaStationId, assessmentCriteriaStation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パラメータ設定のデータを更新します。 
     * @summary パラメータ設定更新API
     * @param {Setting} setting パラメータ設定保存リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentCriteriaApi
     */
    public updateSettings(setting: Setting, options?: AxiosRequestConfig) {
        return AssessmentCriteriaApiFp(this.configuration).updateSettings(setting, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DoormanApi - axios parameter creator
 * @export
 */
export const DoormanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} prefId エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCities: async (prefId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefId' is not null or undefined
            assertParamExists('getCities', 'prefId', prefId)
            const localVarPath = `/core/v1/doorman/area/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (prefId !== undefined) {
                localVarQueryParameter['prefId'] = prefId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doormanPropertyId Doorman プロパティ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoormanProperty: async (doormanPropertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doormanPropertyId' is not null or undefined
            assertParamExists('getDoormanProperty', 'doormanPropertyId', doormanPropertyId)
            const localVarPath = `/core/v1/admin/doorman/properties/{doormanPropertyId}`
                .replace(`{${"doormanPropertyId"}}`, encodeURIComponent(String(doormanPropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} prefId エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLines: async (prefId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prefId' is not null or undefined
            assertParamExists('getLines', 'prefId', prefId)
            const localVarPath = `/core/v1/doorman/railroad/lines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (prefId !== undefined) {
                localVarQueryParameter['prefId'] = prefId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lineId 路線ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations: async (lineId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineId' is not null or undefined
            assertParamExists('getStations', 'lineId', lineId)
            const localVarPath = `/core/v1/doorman/railroad/stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lineId !== undefined) {
                localVarQueryParameter['lineId'] = lineId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cityId 市区町村ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTowns: async (cityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cityId' is not null or undefined
            assertParamExists('getTowns', 'cityId', cityId)
            const localVarPath = `/core/v1/doorman/area/towns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doormanPropertyId 棟ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnits: async (doormanPropertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doormanPropertyId' is not null or undefined
            assertParamExists('getUnits', 'doormanPropertyId', doormanPropertyId)
            const localVarPath = `/core/v1/doorman/properties/{doormanPropertyId}/units`
                .replace(`{${"doormanPropertyId"}}`, encodeURIComponent(String(doormanPropertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DoormanMansionsRequest} doormanMansionsRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMansions: async (doormanMansionsRequest: DoormanMansionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doormanMansionsRequest' is not null or undefined
            assertParamExists('searchMansions', 'doormanMansionsRequest', doormanMansionsRequest)
            const localVarPath = `/core/v1/doorman/search/mansions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doormanMansionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoormanApi - functional programming interface
 * @export
 */
export const DoormanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoormanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} prefId エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCities(prefId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoormanCities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCities(prefId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doormanPropertyId Doorman プロパティ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoormanProperty(doormanPropertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoormanProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoormanProperty(doormanPropertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} prefId エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLines(prefId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoormanLines>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLines(prefId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lineId 路線ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStations(lineId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoormanStations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStations(lineId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cityId 市区町村ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTowns(cityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoormanTowns>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTowns(cityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doormanPropertyId 棟ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnits(doormanPropertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoormanUnits>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnits(doormanPropertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DoormanMansionsRequest} doormanMansionsRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMansions(doormanMansionsRequest: DoormanMansionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoormanMansions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMansions(doormanMansionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoormanApi - factory interface
 * @export
 */
export const DoormanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoormanApiFp(configuration)
    return {
        /**
         * 
         * @param {number} prefId エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCities(prefId: number, options?: any): AxiosPromise<DoormanCities> {
            return localVarFp.getCities(prefId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doormanPropertyId Doorman プロパティ ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoormanProperty(doormanPropertyId: number, options?: any): AxiosPromise<DoormanProperty> {
            return localVarFp.getDoormanProperty(doormanPropertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} prefId エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLines(prefId: number, options?: any): AxiosPromise<DoormanLines> {
            return localVarFp.getLines(prefId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lineId 路線ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations(lineId: number, options?: any): AxiosPromise<DoormanStations> {
            return localVarFp.getStations(lineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cityId 市区町村ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTowns(cityId: number, options?: any): AxiosPromise<DoormanTowns> {
            return localVarFp.getTowns(cityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doormanPropertyId 棟ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnits(doormanPropertyId: number, options?: any): AxiosPromise<DoormanUnits> {
            return localVarFp.getUnits(doormanPropertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DoormanMansionsRequest} doormanMansionsRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMansions(doormanMansionsRequest: DoormanMansionsRequest, options?: any): AxiosPromise<DoormanMansions> {
            return localVarFp.searchMansions(doormanMansionsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoormanApi - object-oriented interface
 * @export
 * @class DoormanApi
 * @extends {BaseAPI}
 */
export class DoormanApi extends BaseAPI {
    /**
     * 
     * @param {number} prefId エリア
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoormanApi
     */
    public getCities(prefId: number, options?: AxiosRequestConfig) {
        return DoormanApiFp(this.configuration).getCities(prefId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doormanPropertyId Doorman プロパティ ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoormanApi
     */
    public getDoormanProperty(doormanPropertyId: number, options?: AxiosRequestConfig) {
        return DoormanApiFp(this.configuration).getDoormanProperty(doormanPropertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} prefId エリア
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoormanApi
     */
    public getLines(prefId: number, options?: AxiosRequestConfig) {
        return DoormanApiFp(this.configuration).getLines(prefId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lineId 路線ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoormanApi
     */
    public getStations(lineId: number, options?: AxiosRequestConfig) {
        return DoormanApiFp(this.configuration).getStations(lineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cityId 市区町村ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoormanApi
     */
    public getTowns(cityId: number, options?: AxiosRequestConfig) {
        return DoormanApiFp(this.configuration).getTowns(cityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doormanPropertyId 棟ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoormanApi
     */
    public getUnits(doormanPropertyId: number, options?: AxiosRequestConfig) {
        return DoormanApiFp(this.configuration).getUnits(doormanPropertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DoormanMansionsRequest} doormanMansionsRequest body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoormanApi
     */
    public searchMansions(doormanMansionsRequest: DoormanMansionsRequest, options?: AxiosRequestConfig) {
        return DoormanApiFp(this.configuration).searchMansions(doormanMansionsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FollowupBlacklistApi - axios parameter creator
 * @export
 */
export const FollowupBlacklistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BlacklistColumn} blacklistColumn body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBlacklistColumn: async (blacklistColumn: BlacklistColumn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blacklistColumn' is not null or undefined
            assertParamExists('createBlacklistColumn', 'blacklistColumn', blacklistColumn)
            const localVarPath = `/core/v1/followup/blacklists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blacklistColumn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlacklistColumns: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/followup/blacklists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FollowupBlacklistApi - functional programming interface
 * @export
 */
export const FollowupBlacklistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FollowupBlacklistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BlacklistColumn} blacklistColumn body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBlacklistColumn(blacklistColumn: BlacklistColumn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlacklistColumn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBlacklistColumn(blacklistColumn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlacklistColumns(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlacklistColumns>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlacklistColumns(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FollowupBlacklistApi - factory interface
 * @export
 */
export const FollowupBlacklistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FollowupBlacklistApiFp(configuration)
    return {
        /**
         * 
         * @param {BlacklistColumn} blacklistColumn body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBlacklistColumn(blacklistColumn: BlacklistColumn, options?: any): AxiosPromise<BlacklistColumn> {
            return localVarFp.createBlacklistColumn(blacklistColumn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlacklistColumns(options?: any): AxiosPromise<BlacklistColumns> {
            return localVarFp.getBlacklistColumns(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FollowupBlacklistApi - object-oriented interface
 * @export
 * @class FollowupBlacklistApi
 * @extends {BaseAPI}
 */
export class FollowupBlacklistApi extends BaseAPI {
    /**
     * 
     * @param {BlacklistColumn} blacklistColumn body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupBlacklistApi
     */
    public createBlacklistColumn(blacklistColumn: BlacklistColumn, options?: AxiosRequestConfig) {
        return FollowupBlacklistApiFp(this.configuration).createBlacklistColumn(blacklistColumn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupBlacklistApi
     */
    public getBlacklistColumns(options?: AxiosRequestConfig) {
        return FollowupBlacklistApiFp(this.configuration).getBlacklistColumns(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FollowupMailDeliverySummaryApi - axios parameter creator
 * @export
 */
export const FollowupMailDeliverySummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startDate 検索する開始送信日
         * @param {string} endDate 検索する終了送信日
         * @param {number} [memberId] 会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailDeliverySummaries: async (startDate: string, endDate: string, memberId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getMailDeliverySummaries', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getMailDeliverySummaries', 'endDate', endDate)
            const localVarPath = `/core/v1/admin/followup/mailDeliverySummaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FollowupMailDeliverySummaryApi - functional programming interface
 * @export
 */
export const FollowupMailDeliverySummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FollowupMailDeliverySummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startDate 検索する開始送信日
         * @param {string} endDate 検索する終了送信日
         * @param {number} [memberId] 会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailDeliverySummaries(startDate: string, endDate: string, memberId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailDeliverySummaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailDeliverySummaries(startDate, endDate, memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FollowupMailDeliverySummaryApi - factory interface
 * @export
 */
export const FollowupMailDeliverySummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FollowupMailDeliverySummaryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} startDate 検索する開始送信日
         * @param {string} endDate 検索する終了送信日
         * @param {number} [memberId] 会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailDeliverySummaries(startDate: string, endDate: string, memberId?: number, options?: any): AxiosPromise<MailDeliverySummaries> {
            return localVarFp.getMailDeliverySummaries(startDate, endDate, memberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FollowupMailDeliverySummaryApi - object-oriented interface
 * @export
 * @class FollowupMailDeliverySummaryApi
 * @extends {BaseAPI}
 */
export class FollowupMailDeliverySummaryApi extends BaseAPI {
    /**
     * 
     * @param {string} startDate 検索する開始送信日
     * @param {string} endDate 検索する終了送信日
     * @param {number} [memberId] 会員ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailDeliverySummaryApi
     */
    public getMailDeliverySummaries(startDate: string, endDate: string, memberId?: number, options?: AxiosRequestConfig) {
        return FollowupMailDeliverySummaryApiFp(this.configuration).getMailDeliverySummaries(startDate, endDate, memberId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FollowupMailsApi - axios parameter creator
 * @export
 */
export const FollowupMailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 問合せリンク画像登録
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInquiryImage: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('addInquiryImage', 'image', image)
            const localVarPath = `/core/v1/admin/followup/mails/inquiryImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateConditions} aggregateConditions body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateMailStatuses: async (aggregateConditions: AggregateConditions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateConditions' is not null or undefined
            assertParamExists('aggregateMailStatuses', 'aggregateConditions', aggregateConditions)
            const localVarPath = `/core/v1/followup/mails/statuses/aggregate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateConditions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FollowupMails} followupMails body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateFollowupMails: async (followupMails: FollowupMails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'followupMails' is not null or undefined
            assertParamExists('createOrUpdateFollowupMails', 'followupMails', followupMails)
            const localVarPath = `/core/v1/followup/mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(followupMails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 問合せリンク画像削除
         * @param {InquiryImage} inquiryImage body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInquiryImage: async (inquiryImage: InquiryImage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryImage' is not null or undefined
            assertParamExists('deleteInquiryImage', 'inquiryImage', inquiryImage)
            const localVarPath = `/core/v1/admin/followup/mails/inquiryImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquiryImage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mailId メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowupMail: async (mailId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('getFollowupMail', 'mailId', mailId)
            const localVarPath = `/core/v1/followup/mails/{mailId}`
                .replace(`{${"mailId"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [id] 複数のメールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowupMails: async (id?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/followup/mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 提案物件一覧取得
         * @param {number} memberId 会員ID
         * @param {string} startDate 検索する開始送信日
         * @param {string} endDate 検索する終了送信日
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedProperties: async (memberId: number, startDate: string, endDate: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getProposedProperties', 'memberId', memberId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getProposedProperties', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getProposedProperties', 'endDate', endDate)
            const localVarPath = `/core/v1/admin/followup/proposedProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テストメール送信
         * @param {SendTestMailRequest} sendTestMailRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestMail: async (sendTestMailRequest: SendTestMailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendTestMailRequest' is not null or undefined
            assertParamExists('sendTestMail', 'sendTestMailRequest', sendTestMailRequest)
            const localVarPath = `/core/v1/admin/followup/mails/sendTestMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendTestMailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mailId メールID
         * @param {FollowupMail} followupMail body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFollowupMail: async (mailId: string, followupMail: FollowupMail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('updateFollowupMail', 'mailId', mailId)
            // verify required parameter 'followupMail' is not null or undefined
            assertParamExists('updateFollowupMail', 'followupMail', followupMail)
            const localVarPath = `/core/v1/followup/mails/{mailId}`
                .replace(`{${"mailId"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(followupMail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FollowupMailsApi - functional programming interface
 * @export
 */
export const FollowupMailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FollowupMailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 問合せリンク画像登録
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInquiryImage(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInquiryImage(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AggregateConditions} aggregateConditions body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateMailStatuses(aggregateConditions: AggregateConditions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateMailStatuses(aggregateConditions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FollowupMails} followupMails body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateFollowupMails(followupMails: FollowupMails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowupMails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateFollowupMails(followupMails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 問合せリンク画像削除
         * @param {InquiryImage} inquiryImage body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInquiryImage(inquiryImage: InquiryImage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInquiryImage(inquiryImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mailId メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowupMail(mailId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowupMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowupMail(mailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [id] 複数のメールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowupMails(id?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowupMails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowupMails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 提案物件一覧取得
         * @param {number} memberId 会員ID
         * @param {string} startDate 検索する開始送信日
         * @param {string} endDate 検索する終了送信日
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposedProperties(memberId: number, startDate: string, endDate: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposedProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposedProperties(memberId, startDate, endDate, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テストメール送信
         * @param {SendTestMailRequest} sendTestMailRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestMail(sendTestMailRequest: SendTestMailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestMail(sendTestMailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mailId メールID
         * @param {FollowupMail} followupMail body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFollowupMail(mailId: string, followupMail: FollowupMail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowupMail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFollowupMail(mailId, followupMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FollowupMailsApi - factory interface
 * @export
 */
export const FollowupMailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FollowupMailsApiFp(configuration)
    return {
        /**
         * 問合せリンク画像登録
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInquiryImage(image: any, options?: any): AxiosPromise<InquiryImage> {
            return localVarFp.addInquiryImage(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateConditions} aggregateConditions body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateMailStatuses(aggregateConditions: AggregateConditions, options?: any): AxiosPromise<void> {
            return localVarFp.aggregateMailStatuses(aggregateConditions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FollowupMails} followupMails body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateFollowupMails(followupMails: FollowupMails, options?: any): AxiosPromise<FollowupMails> {
            return localVarFp.createOrUpdateFollowupMails(followupMails, options).then((request) => request(axios, basePath));
        },
        /**
         * 問合せリンク画像削除
         * @param {InquiryImage} inquiryImage body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInquiryImage(inquiryImage: InquiryImage, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInquiryImage(inquiryImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mailId メールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowupMail(mailId: string, options?: any): AxiosPromise<FollowupMail> {
            return localVarFp.getFollowupMail(mailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [id] 複数のメールID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowupMails(id?: Array<string>, options?: any): AxiosPromise<FollowupMails> {
            return localVarFp.getFollowupMails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 提案物件一覧取得
         * @param {number} memberId 会員ID
         * @param {string} startDate 検索する開始送信日
         * @param {string} endDate 検索する終了送信日
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedProperties(memberId: number, startDate: string, endDate: string, offset?: number, limit?: number, options?: any): AxiosPromise<ProposedProperties> {
            return localVarFp.getProposedProperties(memberId, startDate, endDate, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * テストメール送信
         * @param {SendTestMailRequest} sendTestMailRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestMail(sendTestMailRequest: SendTestMailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendTestMail(sendTestMailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mailId メールID
         * @param {FollowupMail} followupMail body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFollowupMail(mailId: string, followupMail: FollowupMail, options?: any): AxiosPromise<FollowupMail> {
            return localVarFp.updateFollowupMail(mailId, followupMail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FollowupMailsApi - object-oriented interface
 * @export
 * @class FollowupMailsApi
 * @extends {BaseAPI}
 */
export class FollowupMailsApi extends BaseAPI {
    /**
     * 問合せリンク画像登録
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public addInquiryImage(image: any, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).addInquiryImage(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateConditions} aggregateConditions body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public aggregateMailStatuses(aggregateConditions: AggregateConditions, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).aggregateMailStatuses(aggregateConditions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FollowupMails} followupMails body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public createOrUpdateFollowupMails(followupMails: FollowupMails, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).createOrUpdateFollowupMails(followupMails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 問合せリンク画像削除
     * @param {InquiryImage} inquiryImage body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public deleteInquiryImage(inquiryImage: InquiryImage, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).deleteInquiryImage(inquiryImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mailId メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public getFollowupMail(mailId: string, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).getFollowupMail(mailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [id] 複数のメールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public getFollowupMails(id?: Array<string>, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).getFollowupMails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 提案物件一覧取得
     * @param {number} memberId 会員ID
     * @param {string} startDate 検索する開始送信日
     * @param {string} endDate 検索する終了送信日
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public getProposedProperties(memberId: number, startDate: string, endDate: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).getProposedProperties(memberId, startDate, endDate, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テストメール送信
     * @param {SendTestMailRequest} sendTestMailRequest body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public sendTestMail(sendTestMailRequest: SendTestMailRequest, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).sendTestMail(sendTestMailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mailId メールID
     * @param {FollowupMail} followupMail body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowupMailsApi
     */
    public updateFollowupMail(mailId: string, followupMail: FollowupMail, options?: AxiosRequestConfig) {
        return FollowupMailsApiFp(this.configuration).updateFollowupMail(mailId, followupMail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiriesApi - axios parameter creator
 * @export
 */
export const InquiriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 問合せ検索API
         * @param {string} [from] 問合せ日（開始）
         * @param {string} [to] 問合せ日（終了）
         * @param {string} [name] 氏名
         * @param {InquiryType} [type] 種別
         * @param {number} [inquiryId] 問合せID
         * @param {InquiryStatus} [status] ステータス
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiries: async (from?: string, to?: string, name?: string, type?: InquiryType, inquiryId?: number, status?: InquiryStatus, partnerId?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/inquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (inquiryId !== undefined) {
                localVarQueryParameter['inquiryId'] = inquiryId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partner_id'] = partnerId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 問合せ取得API
         * @param {number} inquiryId 問合せID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiry: async (inquiryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('getInquiry', 'inquiryId', inquiryId)
            const localVarPath = `/core/v1/admin/inquiries/{inquiryId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Inquiry} inquiry body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInquiries: async (inquiry: Inquiry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiry' is not null or undefined
            assertParamExists('postInquiries', 'inquiry', inquiry)
            const localVarPath = `/core/v1/inquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquiry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} inquiryId 問合せID
         * @param {UpdateInquiryRequest} updateInquiryRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInquiries: async (inquiryId: number, updateInquiryRequest: UpdateInquiryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('updateInquiries', 'inquiryId', inquiryId)
            // verify required parameter 'updateInquiryRequest' is not null or undefined
            assertParamExists('updateInquiries', 'updateInquiryRequest', updateInquiryRequest)
            const localVarPath = `/core/v1/inquiries/{inquiryId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInquiryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 問合せ更新API
         * @param {number} inquiryId 問合せID
         * @param {UpdateInquiryAdminRequest} updateInquiryAdminRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInquiryAdmin: async (inquiryId: number, updateInquiryAdminRequest: UpdateInquiryAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquiryId' is not null or undefined
            assertParamExists('updateInquiryAdmin', 'inquiryId', inquiryId)
            // verify required parameter 'updateInquiryAdminRequest' is not null or undefined
            assertParamExists('updateInquiryAdmin', 'updateInquiryAdminRequest', updateInquiryAdminRequest)
            const localVarPath = `/core/v1/admin/inquiries/{inquiryId}`
                .replace(`{${"inquiryId"}}`, encodeURIComponent(String(inquiryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInquiryAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiriesApi - functional programming interface
 * @export
 */
export const InquiriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 問合せ検索API
         * @param {string} [from] 問合せ日（開始）
         * @param {string} [to] 問合せ日（終了）
         * @param {string} [name] 氏名
         * @param {InquiryType} [type] 種別
         * @param {number} [inquiryId] 問合せID
         * @param {InquiryStatus} [status] ステータス
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiries(from?: string, to?: string, name?: string, type?: InquiryType, inquiryId?: number, status?: InquiryStatus, partnerId?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiries(from, to, name, type, inquiryId, status, partnerId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 問合せ取得API
         * @param {number} inquiryId 問合せID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInquiry(inquiryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInquiry(inquiryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Inquiry} inquiry body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInquiries(inquiry: Inquiry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInquiries(inquiry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} inquiryId 問合せID
         * @param {UpdateInquiryRequest} updateInquiryRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInquiries(inquiryId: number, updateInquiryRequest: UpdateInquiryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInquiries(inquiryId, updateInquiryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 問合せ更新API
         * @param {number} inquiryId 問合せID
         * @param {UpdateInquiryAdminRequest} updateInquiryAdminRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInquiryAdmin(inquiryId: number, updateInquiryAdminRequest: UpdateInquiryAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInquiryAdmin(inquiryId, updateInquiryAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiriesApi - factory interface
 * @export
 */
export const InquiriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiriesApiFp(configuration)
    return {
        /**
         * 
         * @summary 問合せ検索API
         * @param {string} [from] 問合せ日（開始）
         * @param {string} [to] 問合せ日（終了）
         * @param {string} [name] 氏名
         * @param {InquiryType} [type] 種別
         * @param {number} [inquiryId] 問合せID
         * @param {InquiryStatus} [status] ステータス
         * @param {string} [partnerId] パートナーID
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiries(from?: string, to?: string, name?: string, type?: InquiryType, inquiryId?: number, status?: InquiryStatus, partnerId?: string, limit?: number, offset?: number, options?: any): AxiosPromise<Inquiries> {
            return localVarFp.getInquiries(from, to, name, type, inquiryId, status, partnerId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 問合せ取得API
         * @param {number} inquiryId 問合せID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInquiry(inquiryId: number, options?: any): AxiosPromise<Inquiry> {
            return localVarFp.getInquiry(inquiryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Inquiry} inquiry body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInquiries(inquiry: Inquiry, options?: any): AxiosPromise<Inquiry> {
            return localVarFp.postInquiries(inquiry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} inquiryId 問合せID
         * @param {UpdateInquiryRequest} updateInquiryRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInquiries(inquiryId: number, updateInquiryRequest: UpdateInquiryRequest, options?: any): AxiosPromise<Inquiry> {
            return localVarFp.updateInquiries(inquiryId, updateInquiryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 問合せ更新API
         * @param {number} inquiryId 問合せID
         * @param {UpdateInquiryAdminRequest} updateInquiryAdminRequest body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInquiryAdmin(inquiryId: number, updateInquiryAdminRequest: UpdateInquiryAdminRequest, options?: any): AxiosPromise<Inquiry> {
            return localVarFp.updateInquiryAdmin(inquiryId, updateInquiryAdminRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiriesApi - object-oriented interface
 * @export
 * @class InquiriesApi
 * @extends {BaseAPI}
 */
export class InquiriesApi extends BaseAPI {
    /**
     * 
     * @summary 問合せ検索API
     * @param {string} [from] 問合せ日（開始）
     * @param {string} [to] 問合せ日（終了）
     * @param {string} [name] 氏名
     * @param {InquiryType} [type] 種別
     * @param {number} [inquiryId] 問合せID
     * @param {InquiryStatus} [status] ステータス
     * @param {string} [partnerId] パートナーID
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiriesApi
     */
    public getInquiries(from?: string, to?: string, name?: string, type?: InquiryType, inquiryId?: number, status?: InquiryStatus, partnerId?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return InquiriesApiFp(this.configuration).getInquiries(from, to, name, type, inquiryId, status, partnerId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 問合せ取得API
     * @param {number} inquiryId 問合せID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiriesApi
     */
    public getInquiry(inquiryId: number, options?: AxiosRequestConfig) {
        return InquiriesApiFp(this.configuration).getInquiry(inquiryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Inquiry} inquiry body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiriesApi
     */
    public postInquiries(inquiry: Inquiry, options?: AxiosRequestConfig) {
        return InquiriesApiFp(this.configuration).postInquiries(inquiry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} inquiryId 問合せID
     * @param {UpdateInquiryRequest} updateInquiryRequest body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiriesApi
     */
    public updateInquiries(inquiryId: number, updateInquiryRequest: UpdateInquiryRequest, options?: AxiosRequestConfig) {
        return InquiriesApiFp(this.configuration).updateInquiries(inquiryId, updateInquiryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 問合せ更新API
     * @param {number} inquiryId 問合せID
     * @param {UpdateInquiryAdminRequest} updateInquiryAdminRequest body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiriesApi
     */
    public updateInquiryAdmin(inquiryId: number, updateInquiryAdminRequest: UpdateInquiryAdminRequest, options?: AxiosRequestConfig) {
        return InquiriesApiFp(this.configuration).updateInquiryAdmin(inquiryId, updateInquiryAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoanableAmountsApi - axios parameter creator
 * @export
 */
export const LoanableAmountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 借入可能金額取得
         * @param {number} annualIncome 年収
         * @param {number} totalLoanAmount 総借入金額
         * @param {number} age 年齢
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanableAmounts: async (annualIncome: number, totalLoanAmount: number, age: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annualIncome' is not null or undefined
            assertParamExists('getLoanableAmounts', 'annualIncome', annualIncome)
            // verify required parameter 'totalLoanAmount' is not null or undefined
            assertParamExists('getLoanableAmounts', 'totalLoanAmount', totalLoanAmount)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('getLoanableAmounts', 'age', age)
            const localVarPath = `/core/v1/loanableAmounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (annualIncome !== undefined) {
                localVarQueryParameter['annualIncome'] = annualIncome;
            }

            if (totalLoanAmount !== undefined) {
                localVarQueryParameter['totalLoanAmount'] = totalLoanAmount;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanableAmountsApi - functional programming interface
 * @export
 */
export const LoanableAmountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoanableAmountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 借入可能金額取得
         * @param {number} annualIncome 年収
         * @param {number} totalLoanAmount 総借入金額
         * @param {number} age 年齢
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanableAmounts(annualIncome: number, totalLoanAmount: number, age: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanableAmountsProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanableAmounts(annualIncome, totalLoanAmount, age, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoanableAmountsApi - factory interface
 * @export
 */
export const LoanableAmountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoanableAmountsApiFp(configuration)
    return {
        /**
         * 借入可能金額取得
         * @param {number} annualIncome 年収
         * @param {number} totalLoanAmount 総借入金額
         * @param {number} age 年齢
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanableAmounts(annualIncome: number, totalLoanAmount: number, age: number, options?: any): AxiosPromise<LoanableAmountsProperty> {
            return localVarFp.getLoanableAmounts(annualIncome, totalLoanAmount, age, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanableAmountsApi - object-oriented interface
 * @export
 * @class LoanableAmountsApi
 * @extends {BaseAPI}
 */
export class LoanableAmountsApi extends BaseAPI {
    /**
     * 借入可能金額取得
     * @param {number} annualIncome 年収
     * @param {number} totalLoanAmount 総借入金額
     * @param {number} age 年齢
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanableAmountsApi
     */
    public getLoanableAmounts(annualIncome: number, totalLoanAmount: number, age: number, options?: AxiosRequestConfig) {
        return LoanableAmountsApiFp(this.configuration).getLoanableAmounts(annualIncome, totalLoanAmount, age, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberApi - axios parameter creator
 * @export
 */
export const MemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Member} member body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember: async (member: Member, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'member' is not null or undefined
            assertParamExists('createMember', 'member', member)
            const localVarPath = `/core/v1/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(member, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberLogically: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/members/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/members/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mailAddress メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberRegisteredMailAddress: async (mailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailAddress' is not null or undefined
            assertParamExists('getMemberRegisteredMailAddress', 'mailAddress', mailAddress)
            const localVarPath = `/core/v1/members/registeredMailAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mailAddress !== undefined) {
                localVarQueryParameter['mailAddress'] = mailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Member} member body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember: async (member: Member, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'member' is not null or undefined
            assertParamExists('updateMember', 'member', member)
            const localVarPath = `/core/v1/members/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(member, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberApi - functional programming interface
 * @export
 */
export const MemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Member} member body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMember(member: Member, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMember(member, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemberLogically(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMemberLogically(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMember(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMember(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mailAddress メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberRegisteredMailAddress(mailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseRegisterMailAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberRegisteredMailAddress(mailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Member} member body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMember(member: Member, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember(member, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberApi - factory interface
 * @export
 */
export const MemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberApiFp(configuration)
    return {
        /**
         * 
         * @param {Member} member body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember(member: Member, options?: any): AxiosPromise<Member> {
            return localVarFp.createMember(member, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberLogically(options?: any): AxiosPromise<void> {
            return localVarFp.deleteMemberLogically(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember(options?: any): AxiosPromise<Member> {
            return localVarFp.getMember(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mailAddress メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberRegisteredMailAddress(mailAddress: string, options?: any): AxiosPromise<ResponseRegisterMailAddress> {
            return localVarFp.getMemberRegisteredMailAddress(mailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Member} member body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember(member: Member, options?: any): AxiosPromise<Member> {
            return localVarFp.updateMember(member, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberApi - object-oriented interface
 * @export
 * @class MemberApi
 * @extends {BaseAPI}
 */
export class MemberApi extends BaseAPI {
    /**
     * 
     * @param {Member} member body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public createMember(member: Member, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).createMember(member, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public deleteMemberLogically(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).deleteMemberLogically(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getMember(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).getMember(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mailAddress メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public getMemberRegisteredMailAddress(mailAddress: string, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).getMemberRegisteredMailAddress(mailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Member} member body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public updateMember(member: Member, options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).updateMember(member, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NegativeAdjustmentsApi - axios parameter creator
 * @export
 */
export const NegativeAdjustmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 査定額マイナス調整値一覧検索
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegativeAdjustments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/assessmentCriteria/negativeAdjustments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 査定額マイナス調整値更新
         * @param {NegativeAdjustments} negativeAdjustments body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNegativeAdjustments: async (negativeAdjustments: NegativeAdjustments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'negativeAdjustments' is not null or undefined
            assertParamExists('updateNegativeAdjustments', 'negativeAdjustments', negativeAdjustments)
            const localVarPath = `/core/v1/admin/assessmentCriteria/negativeAdjustments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(negativeAdjustments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NegativeAdjustmentsApi - functional programming interface
 * @export
 */
export const NegativeAdjustmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NegativeAdjustmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 査定額マイナス調整値一覧検索
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNegativeAdjustments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NegativeAdjustments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNegativeAdjustments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 査定額マイナス調整値更新
         * @param {NegativeAdjustments} negativeAdjustments body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNegativeAdjustments(negativeAdjustments: NegativeAdjustments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNegativeAdjustments(negativeAdjustments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NegativeAdjustmentsApi - factory interface
 * @export
 */
export const NegativeAdjustmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NegativeAdjustmentsApiFp(configuration)
    return {
        /**
         * 査定額マイナス調整値一覧検索
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNegativeAdjustments(options?: any): AxiosPromise<NegativeAdjustments> {
            return localVarFp.getNegativeAdjustments(options).then((request) => request(axios, basePath));
        },
        /**
         * 査定額マイナス調整値更新
         * @param {NegativeAdjustments} negativeAdjustments body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNegativeAdjustments(negativeAdjustments: NegativeAdjustments, options?: any): AxiosPromise<void> {
            return localVarFp.updateNegativeAdjustments(negativeAdjustments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NegativeAdjustmentsApi - object-oriented interface
 * @export
 * @class NegativeAdjustmentsApi
 * @extends {BaseAPI}
 */
export class NegativeAdjustmentsApi extends BaseAPI {
    /**
     * 査定額マイナス調整値一覧検索
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegativeAdjustmentsApi
     */
    public getNegativeAdjustments(options?: AxiosRequestConfig) {
        return NegativeAdjustmentsApiFp(this.configuration).getNegativeAdjustments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 査定額マイナス調整値更新
     * @param {NegativeAdjustments} negativeAdjustments body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NegativeAdjustmentsApi
     */
    public updateNegativeAdjustments(negativeAdjustments: NegativeAdjustments, options?: AxiosRequestConfig) {
        return NegativeAdjustmentsApiFp(this.configuration).updateNegativeAdjustments(negativeAdjustments, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfficeApi - axios parameter creator
 * @export
 */
export const OfficeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Office} office body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffice: async (office: Office, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'office' is not null or undefined
            assertParamExists('createOffice', 'office', office)
            const localVarPath = `/core/v1/admin/offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(office, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} officeId 事業所ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffice: async (officeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('getOffice', 'officeId', officeId)
            const localVarPath = `/core/v1/admin/offices/{officeId}`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffices: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} officeId 事業所ID
         * @param {Office} office body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffice: async (officeId: number, office: Office, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('updateOffice', 'officeId', officeId)
            // verify required parameter 'office' is not null or undefined
            assertParamExists('updateOffice', 'office', office)
            const localVarPath = `/core/v1/admin/offices/{officeId}`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(office, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfficeApi - functional programming interface
 * @export
 */
export const OfficeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfficeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Office} office body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffice(office: Office, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Office>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffice(office, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} officeId 事業所ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffice(officeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Office>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffice(officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffices(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffices(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} officeId 事業所ID
         * @param {Office} office body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOffice(officeId: number, office: Office, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Office>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOffice(officeId, office, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfficeApi - factory interface
 * @export
 */
export const OfficeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfficeApiFp(configuration)
    return {
        /**
         * 
         * @param {Office} office body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffice(office: Office, options?: any): AxiosPromise<Office> {
            return localVarFp.createOffice(office, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} officeId 事業所ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffice(officeId: number, options?: any): AxiosPromise<Office> {
            return localVarFp.getOffice(officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffices(limit?: number, offset?: number, options?: any): AxiosPromise<Offices> {
            return localVarFp.getOffices(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} officeId 事業所ID
         * @param {Office} office body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffice(officeId: number, office: Office, options?: any): AxiosPromise<Office> {
            return localVarFp.updateOffice(officeId, office, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfficeApi - object-oriented interface
 * @export
 * @class OfficeApi
 * @extends {BaseAPI}
 */
export class OfficeApi extends BaseAPI {
    /**
     * 
     * @param {Office} office body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeApi
     */
    public createOffice(office: Office, options?: AxiosRequestConfig) {
        return OfficeApiFp(this.configuration).createOffice(office, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} officeId 事業所ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeApi
     */
    public getOffice(officeId: number, options?: AxiosRequestConfig) {
        return OfficeApiFp(this.configuration).getOffice(officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeApi
     */
    public getOffices(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return OfficeApiFp(this.configuration).getOffices(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} officeId 事業所ID
     * @param {Office} office body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeApi
     */
    public updateOffice(officeId: number, office: Office, options?: AxiosRequestConfig) {
        return OfficeApiFp(this.configuration).updateOffice(officeId, office, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnersApi - axios parameter creator
 * @export
 */
export const PartnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/admin/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersApi - functional programming interface
 * @export
 */
export const PartnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partners>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnersApi - factory interface
 * @export
 */
export const PartnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartners(options?: any): AxiosPromise<Partners> {
            return localVarFp.getPartners(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnersApi - object-oriented interface
 * @export
 * @class PartnersApi
 * @extends {BaseAPI}
 */
export class PartnersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getPartners(options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getPartners(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertiesApi - axios parameter creator
 * @export
 */
export const PropertiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PropertyArea} [area] エリア
         * @param {PropertySort} [sort] 並び順
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractProperties: async (area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/properties/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 物件Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractPropertyDetail: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getContractPropertyDetail', 'propertyId', propertyId)
            const localVarPath = `/core/v1/properties/contracts/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 提案用物件（売出中／購入可能）の一覧取得 
         * @param {number} [maxPrice] 販売価格（上限金額）
         * @param {string} [targetDate] 対象日付
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposingProperties: async (maxPrice?: number, targetDate?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/v1/properties/proposing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (maxPrice !== undefined) {
                localVarQueryParameter['maxPrice'] = maxPrice;
            }

            if (targetDate !== undefined) {
                localVarQueryParameter['targetDate'] = (targetDate as any instanceof Date) ?
                    (targetDate as any).toISOString().substr(0,10) :
                    targetDate;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} maxLoanAmount 借入可能金額
         * @param {boolean} isBuyable 購入可能物件
         * @param {PropertyArea} [area] エリア
         * @param {PropertySort} [sort] 並び順
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [firstPublishedAtFrom] 初回掲載日From
         * @param {string} [firstPublishedAtTo] 初回掲載日To
         * @param {string} [priceUpdatedAtFrom] 価格変更日From
         * @param {string} [priceUpdatedAtTo] 価格変更日To
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleProperties: async (maxLoanAmount: number, isBuyable: boolean, area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, firstPublishedAtFrom?: string, firstPublishedAtTo?: string, priceUpdatedAtFrom?: string, priceUpdatedAtTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'maxLoanAmount' is not null or undefined
            assertParamExists('getSaleProperties', 'maxLoanAmount', maxLoanAmount)
            // verify required parameter 'isBuyable' is not null or undefined
            assertParamExists('getSaleProperties', 'isBuyable', isBuyable)
            const localVarPath = `/core/v1/properties/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (maxLoanAmount !== undefined) {
                localVarQueryParameter['maxLoanAmount'] = maxLoanAmount;
            }

            if (isBuyable !== undefined) {
                localVarQueryParameter['isBuyable'] = isBuyable;
            }

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (firstPublishedAtFrom !== undefined) {
                localVarQueryParameter['firstPublishedAtFrom'] = (firstPublishedAtFrom as any instanceof Date) ?
                    (firstPublishedAtFrom as any).toISOString().substr(0,10) :
                    firstPublishedAtFrom;
            }

            if (firstPublishedAtTo !== undefined) {
                localVarQueryParameter['firstPublishedAtTo'] = (firstPublishedAtTo as any instanceof Date) ?
                    (firstPublishedAtTo as any).toISOString().substr(0,10) :
                    firstPublishedAtTo;
            }

            if (priceUpdatedAtFrom !== undefined) {
                localVarQueryParameter['priceUpdatedAtFrom'] = (priceUpdatedAtFrom as any instanceof Date) ?
                    (priceUpdatedAtFrom as any).toISOString().substr(0,10) :
                    priceUpdatedAtFrom;
            }

            if (priceUpdatedAtTo !== undefined) {
                localVarQueryParameter['priceUpdatedAtTo'] = (priceUpdatedAtTo as any instanceof Date) ?
                    (priceUpdatedAtTo as any).toISOString().substr(0,10) :
                    priceUpdatedAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 物件Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalePropertyDetail: async (propertyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('getSalePropertyDetail', 'propertyId', propertyId)
            const localVarPath = `/core/v1/properties/sales/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertiesApi - functional programming interface
 * @export
 */
export const PropertiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PropertyArea} [area] エリア
         * @param {PropertySort} [sort] 並び順
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractProperties(area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractsProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractProperties(area, sort, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 物件Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractPropertyDetail(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractPropertyDetail(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 提案用物件（売出中／購入可能）の一覧取得 
         * @param {number} [maxPrice] 販売価格（上限金額）
         * @param {string} [targetDate] 対象日付
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposingProperties(maxPrice?: number, targetDate?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Properties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposingProperties(maxPrice, targetDate, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} maxLoanAmount 借入可能金額
         * @param {boolean} isBuyable 購入可能物件
         * @param {PropertyArea} [area] エリア
         * @param {PropertySort} [sort] 並び順
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [firstPublishedAtFrom] 初回掲載日From
         * @param {string} [firstPublishedAtTo] 初回掲載日To
         * @param {string} [priceUpdatedAtFrom] 価格変更日From
         * @param {string} [priceUpdatedAtTo] 価格変更日To
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleProperties(maxLoanAmount: number, isBuyable: boolean, area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, firstPublishedAtFrom?: string, firstPublishedAtTo?: string, priceUpdatedAtFrom?: string, priceUpdatedAtTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesProperties>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleProperties(maxLoanAmount, isBuyable, area, sort, limit, offset, firstPublishedAtFrom, firstPublishedAtTo, priceUpdatedAtFrom, priceUpdatedAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 物件Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalePropertyDetail(propertyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Property>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalePropertyDetail(propertyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertiesApi - factory interface
 * @export
 */
export const PropertiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertiesApiFp(configuration)
    return {
        /**
         * 
         * @param {PropertyArea} [area] エリア
         * @param {PropertySort} [sort] 並び順
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractProperties(area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, options?: any): AxiosPromise<ContractsProperties> {
            return localVarFp.getContractProperties(area, sort, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 物件Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractPropertyDetail(propertyId: number, options?: any): AxiosPromise<Property> {
            return localVarFp.getContractPropertyDetail(propertyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 提案用物件（売出中／購入可能）の一覧取得 
         * @param {number} [maxPrice] 販売価格（上限金額）
         * @param {string} [targetDate] 対象日付
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposingProperties(maxPrice?: number, targetDate?: string, offset?: number, limit?: number, options?: any): AxiosPromise<Properties> {
            return localVarFp.getProposingProperties(maxPrice, targetDate, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} maxLoanAmount 借入可能金額
         * @param {boolean} isBuyable 購入可能物件
         * @param {PropertyArea} [area] エリア
         * @param {PropertySort} [sort] 並び順
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {string} [firstPublishedAtFrom] 初回掲載日From
         * @param {string} [firstPublishedAtTo] 初回掲載日To
         * @param {string} [priceUpdatedAtFrom] 価格変更日From
         * @param {string} [priceUpdatedAtTo] 価格変更日To
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleProperties(maxLoanAmount: number, isBuyable: boolean, area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, firstPublishedAtFrom?: string, firstPublishedAtTo?: string, priceUpdatedAtFrom?: string, priceUpdatedAtTo?: string, options?: any): AxiosPromise<SalesProperties> {
            return localVarFp.getSaleProperties(maxLoanAmount, isBuyable, area, sort, limit, offset, firstPublishedAtFrom, firstPublishedAtTo, priceUpdatedAtFrom, priceUpdatedAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 物件Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalePropertyDetail(propertyId: number, options?: any): AxiosPromise<Property> {
            return localVarFp.getSalePropertyDetail(propertyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertiesApi - object-oriented interface
 * @export
 * @class PropertiesApi
 * @extends {BaseAPI}
 */
export class PropertiesApi extends BaseAPI {
    /**
     * 
     * @param {PropertyArea} [area] エリア
     * @param {PropertySort} [sort] 並び順
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public getContractProperties(area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).getContractProperties(area, sort, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 物件Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public getContractPropertyDetail(propertyId: number, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).getContractPropertyDetail(propertyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 提案用物件（売出中／購入可能）の一覧取得 
     * @param {number} [maxPrice] 販売価格（上限金額）
     * @param {string} [targetDate] 対象日付
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public getProposingProperties(maxPrice?: number, targetDate?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).getProposingProperties(maxPrice, targetDate, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} maxLoanAmount 借入可能金額
     * @param {boolean} isBuyable 購入可能物件
     * @param {PropertyArea} [area] エリア
     * @param {PropertySort} [sort] 並び順
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {string} [firstPublishedAtFrom] 初回掲載日From
     * @param {string} [firstPublishedAtTo] 初回掲載日To
     * @param {string} [priceUpdatedAtFrom] 価格変更日From
     * @param {string} [priceUpdatedAtTo] 価格変更日To
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public getSaleProperties(maxLoanAmount: number, isBuyable: boolean, area?: PropertyArea, sort?: PropertySort, limit?: number, offset?: number, firstPublishedAtFrom?: string, firstPublishedAtTo?: string, priceUpdatedAtFrom?: string, priceUpdatedAtTo?: string, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).getSaleProperties(maxLoanAmount, isBuyable, area, sort, limit, offset, firstPublishedAtFrom, firstPublishedAtTo, priceUpdatedAtFrom, priceUpdatedAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 物件Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesApi
     */
    public getSalePropertyDetail(propertyId: number, options?: AxiosRequestConfig) {
        return PropertiesApiFp(this.configuration).getSalePropertyDetail(propertyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyImageApi - axios parameter creator
 * @export
 */
export const PropertyImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPropertyImage: async (propertyId: number, image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('addPropertyImage', 'propertyId', propertyId)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('addPropertyImage', 'image', image)
            const localVarPath = `/core/v1/admin/properties/{propertyId}/images`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {number} imageId 掲載画像ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyImage: async (propertyId: number, imageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('deletePropertyImage', 'propertyId', propertyId)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deletePropertyImage', 'imageId', imageId)
            const localVarPath = `/core/v1/admin/properties/{propertyId}/images/{imageId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {Array<PropertyImage>} propertyImage body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyImages: async (propertyId: number, propertyImage: Array<PropertyImage>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('updatePropertyImages', 'propertyId', propertyId)
            // verify required parameter 'propertyImage' is not null or undefined
            assertParamExists('updatePropertyImages', 'propertyImage', propertyImage)
            const localVarPath = `/core/v1/admin/properties/{propertyId}/images`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyImage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyImageApi - functional programming interface
 * @export
 */
export const PropertyImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPropertyImage(propertyId: number, image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPropertyImage(propertyId, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {number} imageId 掲載画像ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyImage(propertyId: number, imageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyImage(propertyId, imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {Array<PropertyImage>} propertyImage body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePropertyImages(propertyId: number, propertyImage: Array<PropertyImage>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropertyImages(propertyId, propertyImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyImageApi - factory interface
 * @export
 */
export const PropertyImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyImageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPropertyImage(propertyId: number, image: any, options?: any): AxiosPromise<PropertyImage> {
            return localVarFp.addPropertyImage(propertyId, image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {number} imageId 掲載画像ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyImage(propertyId: number, imageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePropertyImage(propertyId, imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propertyId 物件ID
         * @param {Array<PropertyImage>} propertyImage body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePropertyImages(propertyId: number, propertyImage: Array<PropertyImage>, options?: any): AxiosPromise<Array<PropertyImage>> {
            return localVarFp.updatePropertyImages(propertyId, propertyImage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyImageApi - object-oriented interface
 * @export
 * @class PropertyImageApi
 * @extends {BaseAPI}
 */
export class PropertyImageApi extends BaseAPI {
    /**
     * 
     * @param {number} propertyId 物件ID
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyImageApi
     */
    public addPropertyImage(propertyId: number, image: any, options?: AxiosRequestConfig) {
        return PropertyImageApiFp(this.configuration).addPropertyImage(propertyId, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 物件ID
     * @param {number} imageId 掲載画像ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyImageApi
     */
    public deletePropertyImage(propertyId: number, imageId: number, options?: AxiosRequestConfig) {
        return PropertyImageApiFp(this.configuration).deletePropertyImage(propertyId, imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propertyId 物件ID
     * @param {Array<PropertyImage>} propertyImage body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyImageApi
     */
    public updatePropertyImages(propertyId: number, propertyImage: Array<PropertyImage>, options?: AxiosRequestConfig) {
        return PropertyImageApiFp(this.configuration).updatePropertyImages(propertyId, propertyImage, options).then((request) => request(this.axios, this.basePath));
    }
}


